import { ActionFunctionArgs } from "react-router-dom";
import { userFormAction } from "./userActions";
import { packageFormAction } from "./packageActions";

export const adminActions = async (args: ActionFunctionArgs) => {
  const { params } = args;
  
  switch (params.type) {
    case 'user':
      console.log('adminActions - handling user action');
      return userFormAction(args);
    case 'package':
      console.log('adminActions - handling package action');
      return packageFormAction(args);
    default:
      console.log('adminActions - type not supported:', params.type);
      throw new Error(`Type not supported: ${params.type}`);
  }
};