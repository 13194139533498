import { /*useState,  DragEvent, */ ChangeEvent } from 'react';

interface CustomDragDropFileProps {
    disabled?: boolean;
    onUpload: (file: File) => void;
    acceptedFileTypes?: string[];
    displayFileTypes?: string[]; // prop opcional para mostrar al usuario los tipos de archivos aceptados
}

const CustomDragDropFile: React.FC<CustomDragDropFileProps> = ({ disabled = false, onUpload, acceptedFileTypes = [], displayFileTypes }) => {
    //const [isDragging, setIsDragging] = useState(false);

    const isValidFileType = (file: File): boolean => {
        if (acceptedFileTypes.length === 0) return true;
        const fileType = file.type;
        return acceptedFileTypes.some(type => fileType.includes(type));
    };

/*     const onDragEnter = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (!disabled) setIsDragging(true);
    };

    const onDragLeave = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    };

    const onDragOver = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (!disabled) setIsDragging(true);
    };

    const onDrop = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);

        if (disabled) return;

        const file = e.dataTransfer.files[0];
        if (file && isValidFileType(file)) {
            onUpload(file);
        }
    }; */

    const onFileSelect = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();

        const file = e.target.files?.[0];
        if (file && isValidFileType(file)) {
            console.log('>>> Archivo válido, llamando a onUpload');
            onUpload(file);
        } else {
            console.log('>>> Archivo no válido o no seleccionado');
        }
    };

    return (
/*         <div
            className={`drop-file-input ${isDragging ? 'dragover' : ''} ${disabled ? 'not-upload-allowed' : ''}`}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDragOver={onDragOver}
            onDrop={onDrop}
            onClick={(e) => e.stopPropagation()}
        >
            <div className="drop-file-input-label">
                <img src="/images/cloud-upload.png" alt="" />
                <p>Drag & Drop your files here</p>
                {(displayFileTypes || acceptedFileTypes).length > 0 && (
                    <small className="text-muted">
                        Accepted files: {(displayFileTypes || acceptedFileTypes).join(', ')}
                    </small>
                )}
            </div>
            <input
                type="file"
                name="file"
                onChange={onFileSelect}
                disabled={disabled}
                accept={acceptedFileTypes.join(',')}
                style={{ position: 'absolute', width: '100%', height: '100%', opacity: 0, cursor: 'pointer' }}
            />
        </div> */
        <label
            className={`drop-file-input ${disabled ? 'not-upload-allowed' : ''}`}
            style={{ position: 'relative', display: 'block', cursor: 'pointer' }}
        >
            <input
                type="file"
                name="file"
                onChange={onFileSelect}
                disabled={disabled}
                accept={acceptedFileTypes.join(',')}
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    opacity: 0,
                    cursor: 'pointer',
                    zIndex: 2
                }}
            />
            <div className="drop-file-input-label" style={{ pointerEvents: 'none' }}>
                <img src="/images/cloud-upload.png" alt="" />
                <p>Drag & Drop your files here</p>
                {(displayFileTypes || acceptedFileTypes).length > 0 && (
                    <small className="text-muted">
                        Accepted files: {(displayFileTypes || acceptedFileTypes).join(', ')}
                    </small>
                )}
            </div>
        </label>
    );
};

export default CustomDragDropFile;