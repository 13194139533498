import { useCallback, useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import UserTable from "./UserTable";
import { api } from "../../api";
import { AxiosError } from "axios";
import { deleteUser } from "./userActions";
import { useAuth, ROLE } from "../../auth/AuthContext";
import PackageTable from "./PackageTable";
import { deletePackage, updateAccess } from "./packageActions";
import { PackageAccess } from "./types";
const Manager = () => {

    const { type } = useParams();
    const [selectedOption, setSelectedOption] = useState<string>(type || "user");
    const [packageList, setPackageList] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [userList, setUserList] = useState<any[]>([]);

    const navigate = useNavigate();
    const location = useLocation();

    const { canUser } = useAuth();

    const handleSelectChange = (value: string) => {
        setSelectedOption(value);
        // Usar replace para evitar añadir entradas al historial y mantener la misma ubicación base
        navigate(`/admin/dashboard/${value}`, { 
            replace: true,
            state: location.state // Mantener cualquier estado existente
        });
    }

    const loadUsers = async () => {
        setIsLoading(true);
        try {
            const response = await api.get('/users');
            const { data } = response;
            if (data.success && data.users) {
                setUserList(data.users);
            }
        } catch (err) {
            let message;
            if (err instanceof AxiosError && err.response) {
                message = err.response.data.message;
            } else {
                message = (err as Error).message;
            }
            console.log(">>> [message]", message);
            alert(message);
        } finally {
            setIsLoading(false);
        }
    };

    const loadPackages = async () => {
        setIsLoading(true);
        try {
            const response = await api.get('/packages-s3');
            const { data } = response;
            //console.log('>>> [data]', data);
            if (data.success && data.packages) {
                setPackageList(data.packages);
            }
        } catch (err) {
            let message;
            if (err instanceof AxiosError && err.response) {
                message = err.response.data.message;
            } else {
                message = (err as Error).message;
            }
            console.log(">>> [message]", message);
            alert(message);
        } finally {
            setIsLoading(false);
        }
    }

    const handleDeleteUser = async (userId: string) => {
        if (window.confirm(`Are you sure you want to delete the user ${userId}?`)) {
            try {
                setIsLoading(true);
                const result = await deleteUser(userId);
                alert(result.message);
                if (result.success) {
                    console.log('Reloading users after delete');
                    loadUsers();
                }
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleDeletePackage = async (key: string, access: string) => {
        if (window.confirm(`Are you sure you want to delete the package ${key}?`)) {
            try {
                setIsLoading(true);
                const result = await deletePackage(key, access);
                alert(result.message);
                if (result.success) {
                    console.log('Reloading packages after delete');
                    loadPackages();
                }
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleUpdateAccess = async (key: string, access: string) => {
        const newAccess = access === PackageAccess.RW ? 'READ/WRITE' : 'READ ONLY';
        if (window.confirm(`Are you sure you want to change the access of package "${key}" to ${newAccess as PackageAccess}?`)) {
            
            try {
                setIsLoading(true);
                const result = await updateAccess(key, access);
                alert(result.message);
                if (result.success) {
                    console.log('Reloading packages after update');
                    loadPackages();
                }
            } finally {
                setIsLoading(false);
            }
        }
    };

    // Efecto para cargar datos dependiendo de la opción seleccionada
    useEffect(() => {
        if (selectedOption === "user") {
            console.log("Loading Users...");
            loadUsers();
        } else if (selectedOption === "package") {
            console.log("Loading Packages...");
            loadPackages();
        }
    }, [selectedOption]);

    // Efecto para recargar datos después de crear/editar
    const handleSuccess = useCallback(() => {
        if (selectedOption === "user") {
            loadUsers();
        } else if (selectedOption === "package") {
            loadPackages();
        }
    }, [selectedOption]);

    return (
        <>
            <div className={ isLoading ? "loading" : "" }></div>
            <div className="div-green">
                <div className="white-line"></div>
            </div>
            <div className="report-fields">
                <div className="row mb-2">
                    <div className="col-6 col-sm-4 col-lg-2 me-5 mb-4">
                        <select
                            className="form-select form-select-sm me-3 report-select"
                            aria-label=".form-select-lg"
                            value={selectedOption}
                            onChange={(e) => handleSelectChange(e.target.value)}
                        >
                            <option value="user">User List</option>
                            <option value="package">Packages List</option>
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-10 mx-auto mb-4">
                        <div className="container div-admin-list bg-custom-gray py-5 rounded px-custom position-relative">
                            <div className="position-absolute top-0 end-0 mt-4 me-4">
                                <Link
                                    title={selectedOption === "user" ? "Add User" : "Add Package"}
                                    className="options-table-admin add-icon"
                                    to="create"
                                >
                                    <span className="d-inline-block user-icon-dimensions"></span>
                                </Link>
                            </div>
                            <h5 className="mb-4">{selectedOption === "user" ? "User List" : "Packages List"}</h5>
                            {canUser([ROLE.SUPERADMIN])
                                ? (
                                    <>
                                        {selectedOption === "user" && (
                                            <UserTable
                                                users={userList}
                                                onDeleteUser={handleDeleteUser}
                                            />
                                        )}
                                        {selectedOption === "package" && (
                                            <PackageTable
                                                packages={packageList}
                                                onDeletePackage={handleDeletePackage}
                                                onUpdateAccess={handleUpdateAccess}
                                            />
                                        )}
                                    </>
                                )
                                : (
                                    <div>
                                        <p>You are not authorized to view this page.</p>
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
                <div>
                    <Outlet context={{ onSuccess: handleSuccess} }/>
                </div>
            </div >
        </>
    );
};

export default Manager;
