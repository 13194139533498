import { ActionFunctionArgs } from "react-router-dom";

const loader = ({ params }: ActionFunctionArgs) => {
    window.localStorage.setItem(
        "moodle-console-urlRollback",
        `home/rollback/${params.token}/${params.action}`
    );
    console.log(window.localStorage.getItem("moodle-console-urlRollback"));
    return {
        token: params.token,
        action: params.action
    };
};

export default loader;