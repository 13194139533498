import React, { useState } from "react";

interface ColorPickerProps {
    name?: string; // Nombre del input (opcional)
}

const ColorPicker: React.FC<ColorPickerProps> = ({ name = "color" }) => {
  const [color, setColor] = useState<string>('');

  const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newColor = event.target.value;
    const hexPattern = /^#([0-9A-Fa-f]{0,6})$/;
  
    if (hexPattern.test(newColor) || newColor === "#") {
        //console.log("newColor: ", newColor);
        setColor(newColor);
    }
  };

  return (
    <div className="input-group">
      <div 
        style={{
          width: '38px',
          backgroundColor: color || '#FFFFFF',
          border: '1px solid #ccc',
          borderRadius: '4px 0px 0px 4px'
        }}
      />
        <input
          className="form-control"
          type="text"
          id={name}
          name={name}
          placeholder="#FFFFFF"
          value={color}
          onChange={handleColorChange}
          maxLength={7}
        />
    </div>
  );
};

export default ColorPicker;

