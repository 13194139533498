import { useEffect, useState } from "react";
import { Form, useSubmit, useActionData, useNavigation } from "react-router-dom";
import { UserType, ProfileEnum } from "./types";
import { IActionUserForm } from "./types";
import { useAuth, ROLE } from "../../auth/AuthContext";
interface UserFormProps {
  mode: 'create' | 'edit' | 'reset-pass';
  initialData?: UserType;
  onSuccess: () => void;
}

const UserForm: React.FC<UserFormProps> = ({ mode, initialData, onSuccess }) => {

  const submit = useSubmit();
  const actionData = useActionData() as IActionUserForm;
  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";

  // Estado para seleccionar el perfil de usuario
  const [selectedType, setSelectedType] = useState<string>(initialData?.type || ProfileEnum.MANAGER);

  const { canUser } = useAuth();

  console.log('UserForm render:', { mode, initialData, actionData, selectedType, onSuccess });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    
    // Aquí definimos la ruta dependiendo de mode
    let actionUrl;
    switch (mode) {
      case 'create':
        actionUrl = '/admin/dashboard/user/create';
        break;
      case 'reset-pass':
        actionUrl = `/admin/dashboard/user/reset-pass/${initialData?.id}`;
        break;
      default: // caso 'edit'
        actionUrl = `/admin/dashboard/user/edit/${initialData?.id}`;
    }

    console.log('Enviando formulario a:', actionUrl);

    //create: POST, edit: PUT, reset-pass: POST
    submit(formData, {
      method: mode === 'reset-pass' ? 'post' : mode === 'create' ? 'post' : 'put',
      action: actionUrl,
      // Solo usar replace si la operación anterior fue exitosa para evitar que se vuelva a cargar la página
      replace: actionData?.success || false
    });
  };

  // Actualizar selectedType cuando initialData cambie
  useEffect(() => {
    if (initialData?.type) {
      console.log('initialData.type:', initialData.type);
      setSelectedType(initialData.type);
    }
  }, [initialData]);

  // Ejecutar onSuccess cuando la acción se complete exitosamente
  useEffect(() => {
    if (actionData?.success) {
      console.log('Invocando onSuccess desde UserForm......');
      onSuccess();
    }
  }, [actionData?.success, onSuccess]);

  return (
    <>
      {actionData?.message && (
        <div className={`alert ${actionData.success ? 'alert-success' : 'alert-danger'}`}>
          {actionData.message}
        </div>
      )}

      <Form onSubmit={handleSubmit} noValidate className="form-user">

        {/* Mostrar campos de usuario solo si NO es modo reset password */}
        {mode !== 'reset-pass' && (
          <>
            {/* Renderizar el campo Email solo en modo create dado que es campo id de la tabla usuario, no se puede editar */}
            {mode === 'create' && (

              <div className="form-group mb-3">
                <label>Email:</label>
                <input
                  type="email"
                  name="id"
                  className={`form-control ${actionData?.errors?.id ? 'is-invalid' : ''}`}
                  defaultValue={initialData?.id}
                />
                {actionData?.errors?.id && (
                  <div className="invalid-feedback">{actionData.errors.id}</div>
                )}
              </div>

            )}

            {/* campo de nombre */}
            <div className="form-group mb-3">
              <label>First Name:</label>
              <input
                type="text"
                name="firstName"
                className={`form-control ${actionData?.errors?.firstName ? 'is-invalid' : ''}`}
                defaultValue={initialData?.firstName}
                required
              />
              {actionData?.errors?.firstName && (
                <div className="invalid-feedback">{actionData.errors.firstName}</div>
              )}
            </div>

            {/* campo de apellido no es requerido */}
            <div className="form-group mb-3">
              <label>Last Name:</label>
              <input
                type="text"
                name="lastName"
                className={`form-control`}
                defaultValue={initialData?.lastName}
              />
            </div>
          </>
        )}

        {/* Mostrar campos de password */}
        {(mode === 'create' || mode === 'reset-pass') && (
          <>
            {mode === 'reset-pass' ? (
              // Campos para reset password
              <>
                <div className="form-group mb-3">
                  <label>Current Password:</label>
                  <input
                    type="password"
                    name="password"
                    className={`form-control ${actionData?.errors?.password ? 'is-invalid' : ''}`}
                  />
                  {actionData?.errors?.password && (
                    <div className="invalid-feedback">{actionData.errors.password}</div>
                  )}
                </div>
                <div className="form-group mb-3">
                  <label>New Password:</label>
                  <input
                    type="password"
                    name="newPassword"
                    className={`form-control ${actionData?.errors?.newPassword ? 'is-invalid' : ''}`}
                  />
                  {actionData?.errors?.newPassword && (
                    <div className="invalid-feedback">{actionData.errors.newPassword}</div>
                  )}
                </div>
                <div className="form-group mb-3">
                  <label>Confirm New Password:</label>
                  <input
                    type="password"
                    name="confirmPassword"
                    className={`form-control ${actionData?.errors?.confirmPassword ? 'is-invalid' : ''}`}
                  />
                  {actionData?.errors?.confirmPassword && (
                    <div className="invalid-feedback">{actionData.errors.confirmPassword}</div>
                  )}
                </div>
              </>
            ) : (
              // Campos para create
              <>
                <div className="form-group mb-3">
                  <label>Password:</label>
                  <input
                    type="password"
                    name="password"
                    className={`form-control ${actionData?.errors?.password ? 'is-invalid' : ''}`}
                  />
                  {actionData?.errors?.password && (
                    <div className="invalid-feedback">{actionData.errors.password}</div>
                  )}
                </div>
                <div className="form-group mb-3">
                  <label>Confirm Password:</label>
                  <input
                    type="password"
                    name="confirmPassword"
                    className={`form-control ${actionData?.errors?.confirmPassword ? 'is-invalid' : ''}`}
                  />
                  {actionData?.errors?.confirmPassword && (
                    <div className="invalid-feedback">{actionData.errors.confirmPassword}</div>
                  )}
                </div>
              </>
            )}
          </>
        )}

        {/* Mostrar campo de perfil solo si NO es modo reset password */}
        {mode !== 'reset-pass' && (
          <div className="form-group mb-3">
            <label>Profile:</label>
            <select
              name="type"
              className={`form-control ${actionData?.errors?.type ? 'is-invalid' : ''}`}
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value)}
            >
              <option key={ProfileEnum.MANAGER} value={ProfileEnum.MANAGER}>Manager</option>
              <option key={ProfileEnum.ADMIN} value={ProfileEnum.ADMIN}>Admin</option>
              <option key={ProfileEnum.SUPERADMIN} value={ProfileEnum.SUPERADMIN}>Super Admin</option>
            </select>
            {actionData?.errors?.type && (
              <div className="invalid-feedback">{actionData.errors.type}</div>
            )}
          </div>
        )}
        {canUser([ROLE.SUPERADMIN]) ? (
          <div className="text-end">
            <button
              type="submit"
              className="btn btn-custom mb-3"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Processing...' :
                mode === 'create' ? 'Create User' :
                  mode === 'reset-pass' ? 'Reset Password' : 'Update User'}
            </button>
          </div>
        ) : (
          <div>
            <p>You are not authorized to use this form.</p>
          </div>
        )}
      </Form>
    </>
  );
};

export default UserForm;