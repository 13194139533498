import React, { useState, useRef } from 'react';

interface UploadFileProps {
    name: string; // Input name
    label?: string; // Input name
    acceptTypes: string[]; // Array of accepted file types
    direction?: 'row' | 'column'; // Layout direction
    showTooltip?: boolean; // Enable or disable tooltip
    tooltipText?: string; // Custom tooltip text
}

const CustomUploadFile: React.FC<UploadFileProps> = ({
    name, 
    label = 'Upload File', 
    acceptTypes, 
    direction = 'row',
    showTooltip = false,
    tooltipText = 'Default tooltip text'
}) => {
    const [buttonText, setButtonText] = useState<string>('No file selected');
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;

        if (files && files.length > 0) {
            const file = files[0];

            if (!acceptTypes.includes(file.type)) {
                alert(`Please upload a file of type: ${acceptTypes.join(', ')}.`);
                if (fileInputRef.current) {
                    fileInputRef.current.value = ''; // Clear the input
                }
                setButtonText('No file selected'); // Update button text
            } else {
                setButtonText(file.name); // Update button text with file name
            }
        } else {
            setButtonText('No file selected'); // Reset button text
        }
    };

    // Estilos para el tooltip
    const tooltipStyles: React.CSSProperties = {
        position: 'absolute',
        top: '100%', // Coloca el tooltip justo debajo del ícono
        left: '0',
        backgroundColor: '#43aa44', // Fondo oscuro
        color: '#fff',           // Texto blanco
        padding: '8px 12px',     // Espaciado interno
        borderRadius: '4px',     // Bordes redondeados
        fontSize: '14px',
        fontWeight: 500,
        whiteSpace: 'nowrap',
        zIndex: 100,
        marginTop: '4px'
    };

    // Contenedor del tooltip: permite posicionarlo relativo al contenedor padre
    const containerStyles: React.CSSProperties = {
        position: 'relative',
        display: 'inline-block'
    };

    return (
        <div className="custom-file">
            <label className="form-label" htmlFor={name}>{label}</label>
            {showTooltip && (
                <div style={containerStyles}>
                    <i
                        className="fas fa-info-circle tooltip-icon"
                        onMouseEnter={() => setIsTooltipVisible(true)}
                        onMouseLeave={() => setIsTooltipVisible(false)}
                        style={{ cursor: 'pointer', color: '#43aa44', fontSize: '14px', marginLeft: '3px' }}
                    />
                    {isTooltipVisible && (
                        <div style={tooltipStyles}>
                            {tooltipText}
                        </div>
                    )}
                </div>
            )}
            <div style={{ display: direction === 'row' ? 'inline' : 'flex', alignItems: 'center' }}>
                <input
                    ref={fileInputRef}
                    className="form-control"
                    type="file"
                    id={name}
                    name={name}
                    accept={acceptTypes.join(',')} 
                    onChange={handleFileChange}
                    style={{ display: 'none' }} // Hide the default input
                />
                <button 
                    type="button" 
                    className="btn btn-secondary" 
                    onClick={() => fileInputRef.current?.click()} // Trigger file input click
                >
                    {buttonText} {/* Use state variable for button text */}
                </button>
            </div>
        </div>
    );
};

export default CustomUploadFile;