import { useEffect, useState } from "react";
import { useNavigate, useNavigation, useOutletContext, useParams } from "react-router-dom";
import {  UserType  } from "./types";
import UserForm from "./UserForm";
import { api } from "../../api";
import { AxiosError } from "axios";
import { ROLE, useAuth } from "../../auth/AuthContext";
import PackageForm from "./PackageForm";

interface AdminModalProps {
    mode: 'create' | 'edit' | 'reset-pass';
}

interface ContextType {
  onSuccess: () => void;
}

const getModalTitle = (type: string, mode: string) => {
  if (type === 'user') {
    switch (mode) {
      case 'create':
        return 'Add User';
      case 'edit':
        return 'Edit User';
      case 'reset-pass':
        return 'Reset Password';
      default:
        return 'User';
    }
  } else {
    return mode === 'create' ? 'Add Package' : 'Delete Package';
  }
};

const AdminModal: React.FC<AdminModalProps> = ({ mode }) => {

  const navigate = useNavigate();
  const navigation = useNavigation();
  const { type, userId } = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userData, setUserData] = useState<UserType | undefined>(undefined);

  const { onSuccess } = useOutletContext<ContextType>();
  const { canUser } = useAuth();

  // Cargar datos del usuario para el modal de edición
  useEffect(() => {
    if ((mode === 'edit' || mode === 'reset-pass') && type === 'user' && userId){
      setIsLoading(true);
      api
        .get(`/users/${userId}`)
        .then((response) => {
          const { data } = response;
          //console.log("User: ", data);
          if (data.success && data.user)
            setUserData(data.user);
          setIsLoading(false);
        })
        .catch((err) => {
          let message;

          if (err instanceof AxiosError && err.response) {
            let data = err.response.data;
            message = data.message;
          } else message = err.message;

          console.log(">>> [message]", message);
          alert(message);
          setIsLoading(false);
        });
      }
  }, [mode, type, userId]);

  // Redirigir a la página de administración si se intenta acceder a reset-password con type !== 'user'
  useEffect(() => {
    if (mode === 'reset-pass' && type !== 'user') {
        // Si alguien intenta acceder a reset-password con type !== 'user'
        console.log(">>> Intentando acceder a reset-pass con type ", type);
        navigate(`/admin/dashboard/${type}`);
        return;
    }
}, [mode, type, navigate]);

  return (
    <div className="modal-overlay">
      <div className={isLoading || navigation.state === "submitting" ? "loading" : ""} ></div>
      <div className="modal-actions admin">
        <button
          className="close-modal-button"
          onClick={() => { navigate(`/admin/dashboard/${type}`); }}
        > x </button>
        <h4 className="title-modal-information">
          {getModalTitle(type ?? 'user', mode)}
        </h4>
        <div className="modal-actions-body">
          <div className="section-modal-dbsnap">
            <div className="section-content admin">
              <div className="row">
                {canUser([ROLE.SUPERADMIN])
                  ? (
                    <>
                      {type === 'user' && (
                        <UserForm
                          mode={mode as 'create' | 'edit' | 'reset-pass'}
                          initialData={userData}
                          onSuccess={onSuccess}
                        />
                      )}
                      {type === 'package' && (
                        <PackageForm
                          mode={mode as 'create'}
                          onSuccess={onSuccess}
                        />
                      )}
                    </>
                  )
                  : (
                    <div>
                      <p>You are not authorized to view this page.</p>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminModal;
