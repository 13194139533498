import { ActionFunctionArgs } from "react-router-dom";
import { api } from "../../api";
import { PackageAccess, PackageValidationErrors, ACCEPTED_PACKAGE_TYPES, DISPLAY_PACKAGE_TYPES } from "./types";
import { AxiosError } from "axios";

export const packageFormAction = async ({ request }: ActionFunctionArgs) => {
    const formData = await request.formData();
    const file = formData.get('file') as File;

    console.log('>>> Debugging file en Action:');
    console.log('>>> File es instancia de File?:', file instanceof File);
    if (file instanceof File) {
        console.log('>>> Archivo:', file.name, 'Tamaño:', file.size, 'Tipo:', file.type);
    }

    const access = formData.get('access') as string;
    const isPlugin = formData.get('isPlugin') as string;    
    const category = formData.get('category') as string;
    const pluginPath = formData.get('pluginPath') as string;
    //console.log('>>> File from Action', file);
    console.log('>>> Access from Action', access);
    console.log('>>> isPlugin from Action', isPlugin);
    console.log('>>> category from Action', category);
    console.log('>>> pluginPath from Action', pluginPath);

    // Validación inicial
    const errors: PackageValidationErrors = {};

    // Validación del archivo
    if (!file || (file instanceof File && file.size === 0)) {
        errors.file = 'Please select a file';
    }

    // Validación del tipo de archivo
    if (file) {
        console.log('File type:', file.type);
        if (!ACCEPTED_PACKAGE_TYPES.includes(file.type)) {
            errors.file = `Invalid file type. Accepted types are: ${DISPLAY_PACKAGE_TYPES.join(', ')}`;
        }
    }

    // Validación del tipo de acceso
    if (!access) {
        errors.access = 'Access type is required';
    } else if (!['rw', 'ro'].includes(access)) {
        errors.access = 'Invalid access type';
    }

    // Validaciones para plugins
    if (isPlugin === 'true') {
        if (!category) {
            errors.category = 'Category is required for plugins';
        }

        if (!pluginPath) {
            errors.pluginPath = 'Select the Moodle directory where the plugin will be installed';
        }
    }

    // Si hay errores de validación, retornarlos
    if (Object.keys(errors).length > 0) {
        console.log('>>> [validation errors]', errors);
        return { success: false, errors };
    }

    try {
        // Crear un nuevo FormData para enviar al servidor
        const sendformData = new FormData();
        sendformData.append('file', file);
        sendformData.append('access', access);
        sendformData.append('category', category);
        sendformData.append('pluginPath', pluginPath);
        sendformData.append('isPlugin', isPlugin);

        const response = await api.post('/packages-s3', sendformData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        if (response.data.success) {
            return {
                success: true,
                message: 'Package created successfully'
            };
        } else {
            return {
                success: false,
                message: response.data.message || 'Failed to create package'
            };
        }

    } catch (error) {
        const axiosError = error as AxiosError<{ message: string }>;
        return {
            success: false,
            message: axiosError.response?.data?.message || 'An error occurred while creating the package'
        };
    }
};

export const deletePackage = async (key: string, access: string) => {
    try {
        //Validar acceso y que no sea de solo lectura
        const allowedAccess = ['rw', 'ro'];
        if (!allowedAccess.includes(access)) {
            return { success: false, message: 'Invalid access type. Must be one of the following: read write (rw) or read only (ro).' };
        }

        //Validar que el acceso no sea de solo lectura
        if ((access as PackageAccess) === PackageAccess.RO) {
            return { success: false, message: 'This package is read only. It cannot be deleted.' };
        }

        const response = await api.delete(`/packages-s3`, { data: { key: key } });
        if (response.data.success) {
            return { success: true, message: response.data.message };
        }
        return { success: false, message: 'An error occurred while deleting the package.' };
    } catch (error: any) {
        console.error('Error deleting package:', error);
        let message;
        if (error instanceof AxiosError && error.response) {
            message = error.response.data.message;
        } else {
            message = error.message;
        }
        console.log(">>> [message]", message);
        return {
            success: false,
            message: message || 'An error occurred while processing the request.'
        };
    }
};

export const updateAccess = async (key: string, access: string) => {
    try {
        console.log('>>> [updateAccess]', key, access);
        //Validar acceso
        const allowedAccess = ['rw', 'ro'];
        if (!allowedAccess.includes(access)) {
            return { success: false, message: 'Invalid access type. Must be one of the following: Read/Write (rw) or Read Only (ro).' };
        }

        const response = await api.post(`/packages-s3/${key}`, { access });
        if (response.data.success) {
            return { success: true, message: response.data.message };
        }
        return { success: false, message: 'An error occurred while updating the access.' };
    } catch (error: any) {
        console.error('Error updating access package:', error);
        let message;
        if (error instanceof AxiosError && error.response) {
            message = error.response.data.message;
        } else {
            message = error.message;
        }
        console.log(">>> [message]", message);
        return {
            success: false,
            message: message || 'An error occurred while processing the request.'
        };
    }
};