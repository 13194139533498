import { useEffect, useState } from "react";
import {
  redirect,
  useLoaderData,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import { api } from "../../api";
import { AxiosError } from "axios";
import { useAuth, ROLE } from "../../auth/AuthContext";

const updateResources = (instanceId: any, resourcesToken: any, status: any) => {
  return api.post(`/instances/resources/${instanceId}`, {
    token: resourcesToken,
    status: status,
  });
};

interface ILoaderDelete {
  resources: {
    instanceId?: string;
    instanceName?: string;
    dbInstanceIdentifier?: string;
    instanceIp?: string;
    domain?: string;
    lms?: string;
    volumesData?: [];
    deleteRDS?: boolean; // to determine if the RDS should be removed (if RDS is dedicated or shared)
    dbName?: string;
    deleteManual?: boolean;
  };
  deleteLMS?: boolean;
  error?: boolean;
  messagge?: string;
  region?: string;
  resourcesToken?: string;
  deleteDomain?: boolean;
}

const Delete = () => {
  const data = useLoaderData() as ILoaderDelete;
  const { canUser } = useAuth();
  console.log("data del loader: ", data);

  const instanceId = data.resources?.instanceId
    ? data.resources?.instanceId
    : "";

  const navigation = useNavigation();
  const navigate = useNavigate();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showOuput, setShowOuput] = useState<boolean>(false);
  const [contentOuput, setContentOuput] = useState<string>("");
  const [outputFileId, setOutputFileId] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState("");

  const deleteFunction = async (
    resources: any,
    deleteLMS: any,
    region: any,
    deleteDomain: any
  ) => {
    console.log(resources, deleteLMS, region);

    try {
      // Begin delete process
      setIsLoading(true);
      const response = await api.post(
        `/instances/${resources.instanceId}/delete`,
        {
          deleteLMS: deleteLMS,
          resources: resources,
          region: region,
          deleteDomain: deleteDomain
        }
      );

      const data = response.data;
      console.log("response del delete: ", data);

      if (data.success) {
        setIsLoading(false);
        if (data.success && data.outputFileId) {
          setOutputFileId(data.outputFileId);
          setIsDeleting(true);
          setShowOuput(true);
        } else
          return {
            error: true,
            message:
              "An error has occurred. Cannot display the output of the delete process.",
          };
      }
    } catch (error) {
      setIsLoading(false);
      if (error instanceof AxiosError && error.response) {
        const response = error.response;
        const data = response.data;

        if (response.status === 401) return redirect("/");
        else return { error: true, message: data.message };
      }
    }
  };

  const cancelFunction = async (instanceId: any, resourcesToken: any) => {
    try {
      const response = await updateResources(
        instanceId,
        resourcesToken,
        "canceled"
      );
      const data = response.data;
      console.log("response del cancelFunction: ", data);

      if (data.success) {
        alert("The deletion request has been successfully canceled.");
        //hacer redirect a /instances
      }
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const response = error.response;
        const data = response.data;

        if (response.status === 401) return redirect("/");
        else return { error: true, message: data.message };
      }
    }
  };

  useEffect(() => {
    console.log("useEffect from Delete");
    const updateResourcesStatus = async () => {
      // Update resources with status 'deleted'. Resources were deleted
      const response = await updateResources(
        instanceId,
        data.resourcesToken,
        "deleted"
      );

      const result = response.data;
      console.log("response del updateResources: ", result);

      if (result.success) {
        if (response.status === 200 && result.success) {
          alert("Resources were successfully deleted.");
          //redirect to /instances
        }
      } else {
        if (response.status === 401) return redirect("/");
        else return { error: true, message: result.message };
      }
    };

    if (isDeleting) {
      let timeout: number;

      const loopRequest = () => {
        timeout = window.setTimeout(() => {
          api
            .get(
              `/instances/${instanceId}/delete-process-info?outputFileId=${outputFileId}`
            )
            .then(async (response) => {
              const output = response.data;

              if (response.status === 200) {
                //finished the process
                setIsDeleting(false);
                setContentOuput(output);
                // Check if there are errors in the output and update the resources status accordingly
                if (!output.includes("[Errors]")) {
                  console.log("Resources were deleted successfully");
                  updateResourcesStatus();
                } else {
                  console.log("Error in the delete process");
                }
                // Update resources with status 'deleted'. Resources were deleted
                //updateResourcesStatus();
              } else if (response.status === 206) {
                //continue the process
                setContentOuput(output);
                loopRequest();
              } /* else if (response.status === 500) {
                setIsDeleting(false);
                setContentOuput(output);
              } */
            })
            .catch((error) => {
              // do something with the error
              console.log(error.message);
              setIsDeleting(false);
              setErrorMessage(error.message);
            });
        }, 3000);
      };

      loopRequest();

      return () => {
        if (typeof timeout === "number") clearTimeout(timeout);
      };
    }
  }, [isDeleting, instanceId, outputFileId, data.resourcesToken]);

  return (
    <div className="container">
      <div
        className={
          isDeleting || isLoading || navigation.state === "loading" || navigation.state === "submitting"
            ? "loading"
            : ""
        }
      ></div>
      <div className="row">
        <div className="col-12 pt-5">
          <div className="row">
            <h2 id="delete-resources-title">Delete resources</h2>
            <div className="col-6 col-md-6 col-lg-5">
              <div className="delete-form-container">
                <div id="delete-instance-form" className="mt-2 mb-3">
                  <h3>Details</h3>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="instanceName">
                      Instance Name
                    </label>
                    <input
                      className="form-control"
                      id="instanceName"
                      type="text"
                      value={data?.resources?.instanceName}
                      readOnly={true}
                      disabled={true}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="instanceDomain">
                      {data.deleteDomain ? "Domain" : "The domain will not be deleted"}
                    </label>
                    <input
                      className="form-control"
                      id="instanceDomain"
                      type="text"
                      value={data?.resources?.domain}
                      readOnly={true}
                      disabled={true}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="instanceIp">
                      Public IP Address
                    </label>
                    <input
                      className="form-control"
                      id="instanceIp"
                      type="text"
                      value={data?.resources?.instanceIp}
                      readOnly={true}
                      disabled={true}
                    />
                  </div>
                  {data?.resources?.deleteRDS && (
                    <div className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="dbInstanceIdentifier"
                      >
                        RDS
                      </label>
                      <input
                        className="form-control"
                        id="dbInstanceIdentifier"
                        type="text"
                        value={data?.resources?.dbInstanceIdentifier}
                        readOnly={true}
                        disabled={true}
                      />
                    </div>
                  )}
                  {!data?.resources?.deleteRDS &&
                    !data?.resources?.deleteManual && (
                      <div className="mb-3">
                        <label className="form-label" htmlFor="dbName">
                          Database
                        </label>
                        <input
                          className="form-control"
                          id="dbName"
                          type="text"
                          value={data?.resources?.dbName}
                          readOnly={true}
                          disabled={true}
                        />
                      </div>
                    )}
                  {!data?.resources?.deleteRDS &&
                    data?.resources?.deleteManual && (
                      <div className="mb-3">
                        <label className="form-label" htmlFor="dbName">
                          Database
                        </label>
                        <input
                          className="form-control"
                          id="dbName"
                          type="text"
                          value="The RDS or the database must be manually deleted. Could not be identified by configuration."
                          readOnly={true}
                          disabled={true}
                        />
                      </div>
                    )}
                  {/* { !showOuput && */}
                  <div
                    className="text-end actions-buttons" /* className="d-flex actions-buttons" */
                  >
                    {!showOuput && canUser([ROLE.SUPERADMIN]) && (
                      <>
                        <button
                          type="button"
                          className="btn btn-danger me-3"
                          onClick={(e) => {
                            if (
                              window.confirm(
                                "Are you sure you want to delete these resources?"
                              )
                            )
                              deleteFunction(
                                data.resources,
                                data.deleteLMS,
                                data.region,
                                data.deleteDomain
                              );
                          }}
                        >
                          Delete
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary me-3 cancel-button"
                          onClick={(e) => {
                            if (
                              window.confirm(
                                "Are you sure you want to cancel the deletion request?"
                              )
                            )
                              cancelFunction(
                                data.resources.instanceId,
                                data.resourcesToken
                              );
                          }}
                        >
                          Cancel
                        </button>
                      </>
                    )}
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => navigate("/home/instances")}
                    >
                      Instances List
                    </button>
                  </div>
                  {/* } */}
                  {errorMessage && (
                    <div className="mt-3">
                      <p>{errorMessage}</p>
                    </div>
                  )}
                  {data?.messagge && (
                    <div className="mt-3">
                      <p>{data?.messagge}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {showOuput && (
              <div className="col-6 col-md-6 col-lg-5">
                <div className="delete-details-container mt-2">
                  <h3>Delete Output</h3>
                </div>
                <div id="output-board" className="form-control">
                  {contentOuput}
                </div>
              </div>
            )}
          </div>
          {/* { showOuput && 
                        <div className="row mb-5">
                            <div className="col-12">
                                <div className="delete-details-container mt-2">
                                    <div>
                                        <h3>Delete Output</h3>
                                    </div>
                                </div>
                                <div id="output-board" className="form-control">
                                    { contentOuput }
                                </div>
                            </div>
                        </div>
                    } */}
        </div>
      </div>
    </div>
  );
};

export default Delete;
