import { ActionFunctionArgs } from "react-router-dom";
import { api } from "../../api";
import { ValidationErrors } from "./types";
import { AxiosError } from "axios";

// Funciones de validación
const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

// Validar contraseña fuerte
const isStrongPassword = (password: string): boolean => {
  const minLength = 8;
  const hasNumber = /\d/;
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;
  const hasUpperCase = /[A-Z]/;

  return password.length >= minLength && 
         hasNumber.test(password) && 
         hasSpecialChar.test(password) && 
         hasUpperCase.test(password);
};

const validatePasswordForm = (formData: FormData): ValidationErrors => {
  const errors: ValidationErrors = {};
  const currentPassword = formData.get('password') as string;
  const newPassword = formData.get('newPassword') as string;
  const confirmPassword = formData.get('confirmPassword') as string;

  if (!currentPassword?.trim()) {
    errors.password = 'Current Password is required';
  }

  if (!newPassword?.trim()) {
    errors.newPassword = 'New Password is required';
  } else if (!isStrongPassword(newPassword)) {
    errors.newPassword = 'Must include 8+ chars, numbers, special chars and uppercase';
  }

  if (!confirmPassword?.trim()) {
    errors.confirmPassword = 'Confirm Password is required';
  } else if (newPassword !== confirmPassword) {
    errors.confirmPassword = 'Passwords do not match';
  }

  return errors;
};

// Validar formulario de usuario
const validateUserForm = (formData: FormData, isCreate: boolean): ValidationErrors => {
  const errors: ValidationErrors = {};
  
  //const id = formData.get('id') as string;
  const firstName = formData.get('firstName') as string;
  //const password = formData.get('password') as string;
  const type = formData.get('type') as string;

  if (!firstName?.trim()) {
    errors.firstName = 'First Name is required';
  }

  // Validar email solo en modo create
  if (isCreate) {
    const id = formData.get('id') as string;
    if (!id?.trim()) {
      errors.id = 'Email is required';
    } else if (!isValidEmail(id)) {
      errors.id = 'Email is invalid';
    }
  }

  if (isCreate){
    const password = formData.get('password') as string;
    const confirmPassword = formData.get('confirmPassword') as string;

    if (!password?.trim()) {
      errors.password = 'Password is required';
    } else if (!isStrongPassword(password)) {
      errors.password = 'Must include 8+ chars, numbers, special chars and uppercase';
    }

    if (!confirmPassword?.trim()) {
      errors.confirmPassword = 'Confirm Password is required';
    } else if (password !== confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    }
  }

  if (!type) {
    errors.type = 'Profile is required';
  }

  return errors;
};

/* export async function userFormAction({ request, params }: ActionFunctionArgs) {
    console.log('Action iniciado:', { method: request.method, params });

    try {
        const formData = await request.formData();
        const isCreate = !params.userId;
        const isPassword = request.url.includes('reset-pass');

        // Validar formulario
        const errors = validateUserForm(formData, isCreate);
        if (Object.keys(errors).length > 0) {
            console.log('Errores de validación:', errors);
            return { errors };
        }

        // Preparar datos
        const userData = {
            firstName: formData.get('firstName'),
            lastName: formData.get('lastName'),
            //id: formData.get('id'),
            //password: formData.get('password'),
            type: formData.get('type')
        };

        // Agregar id y password solo en creación
        if (isCreate) {
            Object.assign(userData, {
                id: formData.get('id'),
                password: formData.get('password')
            });
        }

        console.log('Datos del formulario: ', userData);

        if (isCreate) {
            const response = await api.post('/users', userData);
            console.log('Respuesta crear:', response.data);
            if (response.data.success){
                //return { success: true, message: response.data.message };
                alert(response.data.message); // Mostrar mensaje de éxito
                return { success: true };
            }
        } else {
            //enviar userData sin propiedad password
            const response = await api.put(`/users/${params.userId}`, userData);
            console.log('Respuesta editar:', response.data);
            if (response.data.success){
                //return { success: true, message: response.data.message };
                alert(response.data.message); // Mostrar mensaje de éxito
                return { success: true };
            }
        }
    } catch (error: any) {
        console.error('Error en action:', error);
        let message;
        if (error instanceof AxiosError && error.response) {
            message = error.response.data.message;
        } else {
            message = error.message;
        }
        console.log(">>> [message]", message);
        return {
            success: false,
            message: message || 'An error occurred while processing the request'
        };
    }
} */

// Actions para crear y editar usuarios, y resetear contraseña
export async function userFormAction({ request, params }: ActionFunctionArgs) {
  console.log('Action iniciado:', { method: request.method, params });

  try {
    const formData = await request.formData();
    const isCreate = !params.userId;
    const isPassword = request.url.includes('reset-pass');

    // Validar formulario según el modo
    let errors;
    if (isPassword) {
      errors = validatePasswordForm(formData);
    } else {
      errors = validateUserForm(formData, isCreate);
    }

    if (Object.keys(errors).length > 0) {
      console.log('Errores de validación:', errors);
      return { errors };
    }

    // Si es reset password, solo enviamos la nueva contraseña
    if (isPassword) {
      const response = await api.post(`/users/${params.userId}/reset-password`, {
        currentPassword: formData.get('password'),
        newPassword: formData.get('newPassword')
      });

      console.log('Respuesta reset password:', response.data);
      if (response.data.success) {
        alert(response.data.message);
        return { success: true };
      }
    } else {
      // Lógica existente para crear/editar usuario
      const userData = {
        firstName: formData.get('firstName'),
        lastName: formData.get('lastName'),
        type: formData.get('type')
      };

      if (isCreate) {
        Object.assign(userData, {
          id: formData.get('id'),
          password: formData.get('password')
        });

        const response = await api.post('/users', userData);
        console.log('Respuesta crear:', response.data);
        if (response.data.success) {
          alert(response.data.message);
          return { success: true };
        }
      } else {
        const response = await api.put(`/users/${params.userId}`, userData);
        console.log('Respuesta editar:', response.data);
        if (response.data.success) {
          alert(response.data.message);
          return { success: true };
        }
      }
    }
  } catch (error: any) {
    console.error('Error en action:', error);
    let message;
    if (error instanceof AxiosError && error.response) {
      message = error.response.data.message;
    } else {
      message = error.message;
    }
    console.log(">>> [message]", message);
    return {
      success: false,
      message: message || 'An error occurred while processing the request'
    };
  }
}

// Función para eliminar un usuario
export const deleteUser = async (userId: string) => {
  try {
    const response = await api.delete(`/users/${userId}`);
    if (response.data.success) {
      return { success: true, message: response.data.message };
    }
    return { success: false, message: 'Error al eliminar el usuario' };
  } catch (error: any) {
    console.error('Error deleting user:', error);
    let message;
    if (error instanceof AxiosError && error.response) {
      message = error.response.data.message;
    } else {
      message = error.message;
    }
    console.log(">>> [message]", message);
    return {
      success: false,
      message: message || 'An error occurred while processing the request'
    };
  }
};
