import React, { useEffect, useState } from "react";
import {
  Form,
  useActionData,
  useLoaderData,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import { api } from "../../api";
import type {
  SiloType,
  IActionAddInstance,
  ILoaderAddInstance,
  InstallPackageType,
} from "./types";
import ColorPicker from "../ColorPicker/ColorPicker";
import CustomUploadFile from "../CustomUploadFile/CustomUploadFile";

const AddInstance = () => {
  const data = useLoaderData() as ILoaderAddInstance;
  console.log("data del loader: ", data);

  const navigation = useNavigation();
  const navigate = useNavigate();

  const actionData = useActionData() as IActionAddInstance;
  let startCreationProcess = actionData?.startCreationProcess
    ? actionData?.startCreationProcess
    : false;
  const outputFileId: string = actionData?.deployId ? actionData?.deployId : "";
  let blockCreation = actionData?.blockCreation
    ? actionData?.blockCreation
    : false;
  console.log("response del action: ", actionData);
  console.log("outputFileId: ", outputFileId);
  console.log("startCreationProcess: ", startCreationProcess);
  console.log("blockCreation: ", blockCreation);

  const [installInSilo, setInstallInSilo] = useState<boolean>(false);
  const [isMoodle, setIsMoodle] = useState<boolean>(false);
  const [isTotara, setIsTotara] = useState<boolean>(false);
  const [isRDSStandalone, setIsRDSStandalone] = useState<boolean>(false);
  const [contentOuput, setContentOuput] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState("");

  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [showOutput, setShowOutput] = useState<boolean>(false);
  console.log("isCreating: ", isCreating);

  const [isLoading, setIsLoading] = useState<boolean>(false); // is for loading element

  // //Edumy settings fields
  const [isEdumyThemeChecked, setIsEdumyThemeChecked] = useState<boolean>(false);
  const handleCheckboxEdumyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsEdumyThemeChecked(e.target.checked);
  };

  function onChangeInstallPackage(
    value: string /* e: React.FormEvent<HTMLSelectElement> */
  ) {
    //e.preventDefault();
    console.log(value);
    if (value.toString().toUpperCase().includes("MOODLE")) {
      setIsMoodle(true);
      setIsTotara(false);
      return;
    } else if (value.toString().toUpperCase().includes("TOTARA")) {
      setIsMoodle(false);
      setIsTotara(true);
      return;
    } else {
      setIsMoodle(false);
      setIsTotara(false);
      return;
    }
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsRDSStandalone(event.target.checked);
  };

  useEffect(() => {
    setIsCreating(startCreationProcess);
    if (startCreationProcess) setShowOutput(true);
  }, [startCreationProcess]);

  useEffect(() => {
    console.log("useEffect from AddInstance");

    if (isCreating) {
      setIsLoading(true); //show loading element
      let timeout: number;

      const loopRequest = () => {
        timeout = window.setTimeout(() => {
          const url = `/instance/creation-process-output?outputFileId=${outputFileId}`;
          api
            .get(url)
            .then(async (response) => {
              const output = response.data;
              if (response.status === 200) {
                //finished successfully
                setIsCreating(false);
                setContentOuput(output);
                setIsLoading(false); //hide loading element
              } else if (response.status === 206) {
                //continue the process
                setContentOuput(output);
                loopRequest();
              } /* else if (response.status === 500) {
                //finished  with errors
                setIsCreating(false);
                setContentOuput(output);
              } */
            })
            .catch((error) => {
              // do something with the error
              console.log(error.message);
              setIsCreating(false);
              setIsLoading(false); //hide loading element
              setErrorMessage(error.message);
            });
        }, 3000);
      };

      loopRequest();

      return () => {
        if (typeof timeout === "number") clearTimeout(timeout);
      };
    }
  }, [isCreating, outputFileId]);

  return (
    <div className="container ">
      <div
        className={
          isLoading || navigation.state === "loading" || navigation.state === "submitting"
            ? "loading"
            : ""
        }
      ></div>
      <div className="row">
        <div className="col-12 pt-3">
          <h2 id="add-instance-title">Add Instance</h2>
          <div className="row mt-4 add-instance-container h-80">
            <div className="col-6 col-md-6 col-lg-5 content-form-addintstance">
              <h4>LMS Information</h4>
              <Form
                encType="multipart/form-data"
                method="post"
                className="add-form h-100"
                onSubmit={(event) => {
                  if (!window.confirm("Please confirm you want to create new LMS?")
                  ) {
                    event.preventDefault();
                  }
                }}
              >
                <div id="add-instance-form" className="mt-3">
                  <div className="mb-3">
                    <div>
                      <label className="form-label">
                        Select an installation type
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input "
                        type="radio"
                        value="standalone"
                        name="flexRadioDefault"
                        id="standaloneRadio"
                        defaultChecked={true}
                        onChange={(e) => setInstallInSilo(!e.target.checked)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="standaloneRadio"
                      >
                        Standalone
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        // disabled={true}
                        className="form-check-input"
                        type="radio"
                        value="silo"
                        name="flexRadioDefault"
                        id="siloRadio"
                        onChange={(e) => setInstallInSilo(e.target.checked)}
                      />
                      <label className="form-check-label" htmlFor="siloRadio">
                        Silo
                      </label>
                    </div>
                  </div>
                  {installInSilo && (
                    <div className="mb-3">
                      <select
                        className="form-select"
                        name="installationInstance"
                        id="instanceSiloSelect"
                        aria-label="Default select example"
                      >
                        <option value="">Select an instance</option>
                        {data.silos?.map((server: SiloType) =>
                          <option key={server.instanceId} value={server.instanceId}>
                            {server.name}
                          </option>
                        )}
                      </select>
                      {actionData?.error &&
                        actionData?.fieldsErrors?.installationInstance && (
                          <div
                            id="instanceSiloHelp"
                            className="form-text field-error"
                          >
                            Please select an instance.
                          </div>
                        )}
                    </div>
                  )}
                  <div className="mb-1">
                    <label
                      className="form-label"
                      htmlFor="installationPackageSelect"
                    >
                      Installation Package
                    </label>
                    <select
                      className="form-select"
                      name="installationPackage"
                      aria-label="Default select example"
                      onChange={(e) => onChangeInstallPackage(e.target.value)}
                      id="installationPackageSelect"
                    >
                      <option value="">Select an option</option>
                      {/* {data.installers?.map((pckg: InstallPackageType) => (
                        <option key={pckg.name} value={pckg.name}>
                          {pckg.name}
                        </option>
                      ))} */}
                      {data.installers
                        ?.filter((pckg: InstallPackageType) => {
                          return installInSilo ? pckg.name.includes('moodle') : true;
                        })
                        .map((pckg: InstallPackageType) => (
                          <option key={pckg.name} value={pckg.name}>
                            {pckg.name}
                          </option>
                        ))}
                    </select>
                    {actionData?.error &&
                      actionData?.fieldsErrors?.installationPackage && (
                        <div id="packageHelp" className="form-text field-error">
                          Please select an installation package.
                        </div>
                      )}
                  </div>
                  {isMoodle && (
                    <>
                      <div className="mb-3">
                        <div className="form-check m-edumy">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value="edumyTheme"
                            name="edumyThemeCheck"
                            id="flexCheckDefault"
                            checked={isEdumyThemeChecked}
                            onChange={handleCheckboxEdumyChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                          >
                            Edumy Theme
                          </label>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="form-label" htmlFor="moodleInstallationTypeSelect">
                          Moodle Installation Type
                        </label>
                        <select
                          className="form-select"
                          name="moodleInstallationType"
                          id="moodleInstallationTypeSelect"
                          aria-label="Default select example"
                          aria-describedby="moodleInstallationTypeHelp"
                        >
                          <option value="">Select an option</option>
                          <option key="basic" value="basic">Basic</option>
                          <option key="pro" value="pro" disabled={true}>Pro</option>
                        </select>
                      </div>
                    </>
                  )}
                  {isTotara && (
                    <div className="mb-3">
                      <label className="form-label" htmlFor="totaraTypeSelect">
                        Totara Type
                      </label>
                      <select
                        className="form-select"
                        name="totaraType"
                        id="totaraTypeSelect"
                        aria-label="Default select example"
                        aria-describedby="totaraTypeHelp"
                      >
                        <option value="">Select an option</option>
                        <option key="learn" value="learn">
                          {" "}
                          Learn{" "}
                        </option>
                        <option key="engage" value="engage">
                          {" "}
                          Engage{" "}
                        </option>
                        <option key="learn_engage" value="learn_engage">
                          {" "}
                          Learn_engage{" "}
                        </option>
                        <option key="learn_perform" value="learn_perform">
                          {" "}
                          Learn_perform{" "}
                        </option>
                        <option
                          key="learn_perform_engage"
                          value="learn_perform_engage"
                        >
                          {" "}
                          Learn_perform_engage{" "}
                        </option>
                        <option key="perform" value="perform">
                          {" "}
                          Perform{" "}
                        </option>
                        <option key="perform_engage" value="perform_engage">
                          {" "}
                          Perform_engage{" "}
                        </option>
                      </select>
                      {actionData?.error &&
                        actionData?.fieldsErrors?.totaraType && (
                          <div
                            id="totaraTypeHelp"
                            className="form-text field-error"
                          >
                            Please select a Totara type.
                          </div>
                        )}
                    </div>
                  )}
                  <div className="mt-3 mb-3">
                    <label className="form-label" htmlFor="clientInput">
                      Client
                    </label>
                    <input
                      className="form-control"
                      id="clientInput"
                      name="client"
                      type="text"
                      aria-describedby="clientHelp"
                    />
                    {actionData?.error && actionData?.fieldsErrors?.client && (
                      <div id="clientHelp" className="form-text field-error">
                        Please provide a valid client.
                      </div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label
                      className="form-label"
                      htmlFor="contractedUsersInput"
                    >
                      Contracted Users
                    </label>
                    <input
                      className="form-control"
                      name="contractedUsers"
                      id="contractedUsersInput"
                      type="number"
                      aria-describedby="contractedUsersHelp"
                      defaultValue={400}
                    />
                    {actionData?.error &&
                      actionData?.fieldsErrors?.contractedUsers && (
                        <div
                          id="contractedUsersHelp"
                          className="form-text field-error"
                        >
                          The contracted users must be a number.
                        </div>
                      )}
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="branchSelect">
                      Branch
                    </label>
                    <select
                      className="form-select"
                      name="branch"
                      id="branchSelect"
                      aria-label="Default select example"
                      aria-describedby="branchHelp"
                    >
                      <option value="">Select an option</option>
                      <option key="MX" value="MX">
                        {" "}
                        México{" "}
                      </option>
                      <option key="CR" value="CR">
                        {" "}
                        Costa Rica{" "}
                      </option>
                    </select>
                    {actionData?.error && actionData?.fieldsErrors?.branch && (
                      <div id="branchHelp" className="form-text field-error">
                        Please select a branch.
                      </div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="instanceDomainInput">
                      Domain
                    </label>
                    <input
                      className="form-control"
                      id="instanceDomainInput"
                      name="instanceDomain"
                      type="text"
                      aria-describedby="domainHelp"
                    />
                    {actionData?.error && actionData?.fieldsErrors?.domain && (
                      <div id="domainHelp" className="form-text field-error">
                        Only lowercase letters (a-z) and numbers (0-9), except first 
                        character.
                      </div>
                    )}
                  </div>
                  <label className="form-label" htmlFor="instanceNameInput">
                    Instance Name
                  </label>
                  <div className="input-group mb-3">
                    <span className="input-group-text" id="basic-addon1">
                      MDL_
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      name="instanceName"
                      id="instanceNameInput"
                      placeholder="Name"
                      aria-label="Name"
                      aria-describedby="basic-addon1"
                    />
                    {actionData?.error &&
                      actionData?.fieldsErrors?.instanceName && (
                        <div
                          id="instanceNameHelp"
                          className="form-text field-error"
                        >
                          Only use us letters (a-z), numbers (0-9) and the _ symbol, except as first character.
                        </div>
                      )}
                    {actionData?.error &&
                      actionData?.fieldsErrors?.dbNameNotAvailable && (
                        <div
                          id="instanceNameHelp"
                          className="form-text field-error"
                        >
                          This name is used to create the database and is already in use. Please try another name.
                        </div>
                      )}
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="volumeSizeInput">
                      Volume Size
                    </label>
                    <input
                      className="form-control"
                      name="volumeSize"
                      id="volumeSizeInput"
                      type="number"
                      aria-describedby="volumeSizeHelp"
                      defaultValue={50}
                    />
                    {actionData?.error &&
                      actionData?.fieldsErrors?.volumeSize && (
                        <div
                          id="volumeSizeHelp"
                          className="form-text field-error"
                        >
                          The volume size must be an integer greater than 10.
                        </div>
                      )}
                  </div>
                  {!installInSilo && (
                    <>
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor="instanceTypeSelect"
                        >
                          Instance Type
                        </label>
                        <select
                          className="form-select"
                          name="instanceType"
                          id="instanceTypeSelect"
                          aria-label="Default select example"
                          aria-describedby="instanceTypeHelp"
                        >
                          <option value="">Select an option</option>
                          <option key="t4g.small" value="t4g.small">
                            {" "}
                            t4g.small{" "}
                          </option>
                          <option key="t4g.medium" value="t4g.medium">
                            {" "}
                            t4g.medium{" "}
                          </option>
                          <option key="t4g.large" value="t4g.large">
                            {" "}
                            t4g.large{" "}
                          </option>
                        </select>
                        {actionData?.error &&
                          actionData?.fieldsErrors?.instanceType && (
                            <div
                              id="instanceTypeHelp"
                              className="form-text field-error"
                            >
                              Please select an instance type.
                            </div>
                          )}
                      </div>
                      <div className="mb-3">
                        <div className="form-check check-rds">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value="rdsStandalone"
                            name="rdsStandaloneCheck"
                            id="flexCheckDefault"
                            checked={isRDSStandalone}
                            onChange={handleCheckboxChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                          >
                            RDS Standalone
                          </label>
                        </div>
                      </div>
                      {isRDSStandalone && (
                        <>
                          <label
                            className="form-label"
                            htmlFor="dbinstanceNameInput"
                          >
                            RDS Name
                          </label>
                          <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1">
                              mdl-
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              name="dbinstanceName"
                              id="dbinstanceNameInput"
                              placeholder="Name"
                              aria-label="Name"
                              aria-describedby="basic-addon1"
                            />
                            {actionData?.error &&
                              actionData?.fieldsErrors?.dbInstance && (
                                <div
                                  id="dbinstanceNameHelp"
                                  className="form-text field-error"
                                >
                                  Must contain letters, numbers, or -. First
                                  character must be a letter. Can't end with - or
                                  contain two consecutive -.
                                </div>
                              )}
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {isEdumyThemeChecked && (
                    <>
                      <div className="mb-1">
                        <label className="form-label" htmlFor="clientEmailInput">
                          Client Email
                        </label>
                        <input
                          className="form-control"
                          id="clientEmailInput"
                          name="clientEmail"
                          type="text"
                          aria-describedby="clientEmailHelp"
                        />
                        {actionData?.error && actionData?.fieldsErrors?.clientEmail && (
                          <div id="domainHelp" className="form-text field-error">
                            Please provide a valid email.
                          </div>
                        )}
                      </div>
                      <div className="mb-4">
                        <div className="form-check m-edumy">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value="sendEmailToClient"
                            name="sendEmailToClientCheck"
                            id="flexCheckDefault"
                            defaultChecked={true}
                            // checked={isEdumyThemeChecked}
                            // onChange={handleCheckboxEdumyChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                          >
                            Send Email
                          </label>
                        </div>
                      </div>
                      <div className="mb-1">
                        <label className="form-label">Theme Customization</label>
                      </div>
                      <div className="d-flex justify-content-between align-items-start">
                        <div className="mb-3 me-3" style={{ flex: "1" }}>
                          <label className="form-label" htmlFor="primaryColor">Primary Color</label>
                          <div className={`${actionData?.error && actionData?.fieldsErrors?.primaryColor ? "error-border" : ""}`}>
                            <ColorPicker name="primaryColor" />
                          </div>
                        </div>
                        <div className="mb-3" style={{ flex: "1" }}>
                          <label className="form-label" htmlFor="secondaryColor">Secondary Color</label>
                          <div className={`${actionData?.error && actionData?.fieldsErrors?.secondaryColor ? "error-border" : ""}`}>
                            <ColorPicker name="secondaryColor" />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-start">
                        <div className="mb-3 me-3" style={{ flex: "1" }}>
                          <label className="form-label" htmlFor="accentColor">Accent Color</label>
                          <div className={`${actionData?.error && actionData?.fieldsErrors?.accentColor ? "error-border" : ""}`}>
                            <ColorPicker name="accentColor" />
                          </div>
                        </div>
                        <div className="mb-3" style={{ flex: "1" }}>
                          <label className="form-label" htmlFor="footerColor">Footer Color</label>
                          <div className={`${actionData?.error && actionData?.fieldsErrors?.footerColor ? "error-border" : ""}`}>
                            <ColorPicker name="footerColor" />
                          </div>
                        </div>
                      </div>
                      <div className="mb-1">
                        <label className="form-label">Theme Images</label>
                      </div>
                      <div className="logo-content mb-4 d-flex">
                        <div className={`me-3 ${actionData?.error && actionData?.fieldsErrors?.logoDefault ? 'error-border' : ''}`}>
                          <CustomUploadFile name="logoDefault" label="Logo" acceptTypes={['image/png']} direction="column" />
                        </div>
                        <div className={`me-3 ${actionData?.error && actionData?.fieldsErrors?.logoDark ? 'error-border' : ''}`}>
                          <CustomUploadFile name="logoDark" label="Dark Logo" acceptTypes={['image/png']} direction="column" />
                        </div>
                        <div className={`${actionData?.error && actionData?.fieldsErrors?.loginBGImage ? 'error-border' : ''}`}>
                          <CustomUploadFile name="loginBGImage" label="Login Background" 
                            acceptTypes={['image/png']} 
                            direction="column" 
                            showTooltip={true}
                            tooltipText="Recommended size: 1920x1080"
                          />
                        </div>
                      </div>
                    </>
                  )}
                  <div className="creation-buttons">
                    {!showOutput && !blockCreation && (
                      <button type="submit" className="btn btn-primary me-3">
                        Create new LMS
                      </button>
                    )}
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => navigate('/home/instances')}
                    >
                      Instances List
                    </button>
                  </div>
                  {data.error && data?.messageErrors?.installers && (
                    <div className="mt-3">
                      <p>{data?.messageErrors?.installers}</p>
                    </div>
                  )}
                  {data.error && data?.messageErrors?.silos && (
                    <div className="mt-3">
                      <p>{data?.messageErrors?.silos}</p>
                    </div>
                  )}
                  {errorMessage && (
                    <div className="mt-3">
                      <p>{errorMessage}</p>
                    </div>
                  )}
                  {/* { actionData?.error && actionData?.message &&
                                        <div className="mt-3">
                                            <p>{ actionData?.message }</p>
                                        </div>
                                    }    */}
                </div>
              </Form>
            </div>
            {showOutput && (
              <div className="col-6 col-md-6 col-lg-5 d-flex flex-column">
                <h4>Instance Creation Process</h4>
                <div
                  id="addInstace-output-board"
                  className="form-control mt-3 flex-grow-1"
                >
                  {contentOuput}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddInstance;
