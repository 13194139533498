import { redirect } from "react-router-dom";
import { api } from "../api";

const layoutLoader = async () => {
  try {
    console.log('////////////////////////Loader de Layout//////////////////////')
    const data = await api.get("/user");
    if (!data.data) throw new Error("No user");
    return data.data;
  } catch (error) {
    return redirect("/");
  }
};

export default layoutLoader;
