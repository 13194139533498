import { Outlet } from "react-router-dom";
import { useEffect, useState } from "react";
import InstanceRow from "./InstanceRow";
import { useGlobalContext } from "../../GlobalContext";
import { api } from "../../api";
import { AxiosError } from "axios";
import "../css/App.css";
import {
  InstanceType
} from "../types";

const InstanceTable = () => {

  const [instanceList, setInstanceList] = useState<InstanceType[]>([]);

  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { region, filterSearch } = useGlobalContext();

/*   const handleRebootPending = (instanceId: string, rebootPending: boolean) => {
    const updatedInstanceList = instanceList.map((instance) => {
      if (instance.instanceId === instanceId) {
        return {
          ...instance,
          rebootPending: rebootPending,
        };
      }
      return instance;
    });
    setInstanceList(updatedInstanceList);
  }; */

  useEffect(() => {
    console.log("useEffect de InstanceTable: ", region);
    let isSubscribed = true;
    setIsError(false);
    setErrorMessage("");

    setIsLoading(true);
    api
      .get(`/instances?region=${region}`)
      .then((response) => {
        const { data } = response;

        //console.log("data recibida en useEffect: ", data);
        if (isSubscribed) {
          setInstanceList(data.instances);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        let message;

        if (err instanceof AxiosError && err.response) {
          let data = err.response.data;
          message = data.message;
        } else message = err.message;

        console.log(">>> [message]", message);
        setIsError(true);
        setErrorMessage(message);
        setIsLoading(false);
      });

    // cancel any future set state
    return () => {
      isSubscribed = false;
    };
  }, [region]);

  let filteredInstances = instanceList.filter(function (
    instance: InstanceType
  ) {
    if (!filterSearch) return true;

    const hasName =
      instance.name &&
      instance.name.toLowerCase().includes(filterSearch.toLowerCase());
    const hasPublicIpAddress =
      // instance.state === "running" &&
      instance.publicIpAddress &&
      instance.publicIpAddress.includes(filterSearch);
    const hasState = instance.state
      .toLowerCase()
      .includes(filterSearch.toLowerCase());
    const hasInstanceTppe = 
      instance.instaceType && 
      instance.instaceType
        .toLowerCase().includes(filterSearch.toLowerCase());
    const hasInstanceId = instance.instanceId
      .toLowerCase()
      .includes(filterSearch.toLowerCase());

    return (
      hasName ||
      hasPublicIpAddress ||
      hasState ||
      hasInstanceTppe ||
      hasInstanceId
    );
  });

  if (isError && errorMessage)
    return (
      <div className="errorMessage">
        <p>{errorMessage}</p>
      </div>
    );
  else
    return (
      <div className="row">
        <div className={isLoading ? "loading" : ""}></div>
        <div className="col-12">
          <div className="table-responsive">
            <table className="table table-hover" id="main-table">
              <thead>
                <tr>
                  <th scope="col">Status</th>
                  <th scope="col">Name</th>
                  <th scope="col">Type</th>
                  <th scope="col">Version</th>
                  <th scope="col">Instance ID</th>
                  <th scope="col">IP</th>
                  <th scope="col">Certificate</th>
                  <th scope="col">Settings</th>
                </tr>
              </thead>
              <tbody>
                {filteredInstances &&
                  filteredInstances.length > 0 &&
                  filteredInstances.map((instance: any) => (
                    <InstanceRow
                      key={instance.instanceId}
                      instance={instance}
                      setIsLoading={setIsLoading}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* {<Outlet context={{region, handleRebootPending}} />} */}
        {<Outlet context={{region}} />}
      </div>
    );
};

export default InstanceTable;
