import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ROLE, useAuth } from "../../auth/AuthContext";

export interface CustomDropdownOption {
  value: string;
  label: string;         // Text of option to display
  url: string;           // URL to navigate to when clicked
  title?: string;        // Title of option to display on hover
  restricted: boolean;  // Whether the option is restricted to certain roles
}

export interface CustomDropdownProps {
  options: CustomDropdownOption[]; // List of options to display
  buttonTitle?: string;            // Title of the button to display (optional)
  buttonLabel?: string;       // Whether to show the button label (optional)
  buttonClass?: string;            // Class to apply to the button for styling
}

const DropdownMenu = ({ options, buttonTitle = '', buttonClass = "", buttonLabel = '' }: CustomDropdownProps) => {
  const { canUser } = useAuth();

  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownOpen && dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setDropdownOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownOpen]);

  return (
    <div className="dropdown" ref={dropdownRef}>
      <button
        title={buttonTitle}
        className={`btn btn-light me-3 ${buttonClass}`}
        style={{ cursor: 'pointer' }}
        onClick={toggleDropdown}
      >
        {buttonLabel}
      </button>

      {/* Dropdown menu */}
      {dropdownOpen && (
        <div className="dropdown-menu dropdown-custom" style={{ display: 'block' }}>
          {options.map(option => {
            // If the option is restricted and the user doesn't have the required role, don't show it
            if (option.restricted && !canUser([ROLE.SUPERADMIN])) {
              return null;
            }
            return (
              <Link
                key={option.value}
                className="dropdown-item option-custom"
                to={option.url}
                title={option.title || option.label}
                onClick={closeDropdown}
              >
                {option.label}
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
