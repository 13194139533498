import { Form, useActionData, useNavigation, useSubmit } from "react-router-dom";
import { 
    IActionPackageForm, 
    ACCEPTED_PACKAGE_TYPES, 
    DISPLAY_PACKAGE_TYPES,
    MOODLE_PLUGIN_PATHS 
} from "./types";
import { useEffect, useState } from "react";
import CustomDragDropFile from "./CustomDragDropFile";

interface PackageFormProps {
    mode: 'create';
    onSuccess: () => void;
}

const PackageForm: React.FC<PackageFormProps> = ({ mode, onSuccess }) => {
    const submit = useSubmit();
    const actionData = useActionData() as IActionPackageForm;
    const navigation = useNavigation();
    const isSubmitting = navigation.state === "submitting";

    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [selectedAccess, setSelectedAccess] = useState<string>('rw');
    const [isPlugin, setIsPlugin] = useState<boolean>(false);

    const handleFileSelect = (file: File) => {
        setSelectedFile(file);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);

        // Agregar el archivo seleccionado al FormData
        console.log('>>> Estado selectedFile:', selectedFile);
    
        if (selectedFile) {
            console.log('>>> Agregando archivo al FormData');
            console.log('>>> Archivo:', selectedFile.name, 'Tamaño:', selectedFile.size, 'Tipo:', selectedFile.type);
            formData.set('file', selectedFile);
        } else {
            console.log('>>> No hay archivo seleccionado');
        }

        // Agregar isPlugin y los parámetros de plugin si es necesario
        formData.set('isPlugin', isPlugin.toString());

        // Log para ver qué contiene el FormData
        console.log('>>> FormData en handleSubmit:');
        console.log('file:', formData.get('file'));
        // console.log('access:', formData.get('access'));
        // console.log('isPlugin:', formData.get('isPlugin'));
        // console.log('category:', formData.get('category'));
        // console.log('pluginPath:', formData.get('pluginPath'));

        submit(formData, {
            method: 'post',
            action: '/admin/dashboard/package/create',
            encType: 'multipart/form-data', // Asegurarse de que esto esté presente
            // Solo usar replace si la operación anterior fue exitosa para evitar que se vuelva a cargar la página
            replace: actionData?.success || false
        });
    }

    useEffect(() => {
        if (actionData?.success) {
            console.log('Invocando onSuccess desde Package......');
            onSuccess();
        }
    }, [actionData?.success, onSuccess]);

    return (
        <>
            <div className="modal-form-container">
                {actionData?.message && (
                    <div className={`alert ${actionData.success ? 'alert-success' : 'alert-danger'} modal-alert`}>
                        {actionData.message}
                    </div>
                )}


                {mode === 'create' ? (
                    <Form onSubmit={handleSubmit} encType="multipart/form-data" noValidate className="form-user">
                        <div className="form-group mb-1">
                            <div className="form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="isPluginCheck"
                                    name="isPlugin"
                                    checked={isPlugin}
                                    onChange={(e) => setIsPlugin(e.target.checked)}
                                />
                                <label className="form-check-label" htmlFor="isPlugin">
                                    Is Plugin
                                </label>
                            </div>
                        </div>
                        {isPlugin && (
                            <>
                                <div className="form-group mb-3">
                                    <label>Category:</label>
                                    <select
                                        name="category"
                                        className="form-control"
                                        defaultValue="basic"
                                    >
                                        <option value="basic">Basic</option>
                                        <option value="pro">Pro</option>
                                    </select>
                                    {actionData?.errors?.category && (
                                        <div className="invalid-feedback">{actionData.errors.category}</div>
                                    )}
                                </div>
                                <div className="form-group mb-3">
                                    <label>Plugin Path:</label>
                                    <select
                                        name="pluginPath"
                                        className="form-control"
                                        defaultValue={MOODLE_PLUGIN_PATHS[0]}
                                    >
                                        {MOODLE_PLUGIN_PATHS.map((path) => (
                                            <option key={path} value={path}>
                                                {path}
                                            </option>
                                        ))}
                                    </select>
                                    {actionData?.errors?.pluginPath && (
                                        <div className="invalid-feedback">{actionData.errors.pluginPath}</div>
                                    )}
                                </div>
                            </>
                        )}
                        <div className="form-group mb-3">
                            <label>Upload File</label>
                            <CustomDragDropFile
                                onUpload={handleFileSelect}
                                disabled={isSubmitting}
                                acceptedFileTypes={ACCEPTED_PACKAGE_TYPES}
                                displayFileTypes={DISPLAY_PACKAGE_TYPES}
                            />
                            {selectedFile && (
                                <div className="mt-2">
                                    <span className="text-muted">Selected file: </span>
                                    <span className="font-weight-500">{selectedFile.name}</span>
                                </div>
                            )}
                            {actionData?.errors?.file && (
                                <div className="invalid-feedback d-block ms-2">{actionData.errors.file}</div>
                            )}
                        </div>
                        <div className="form-group mb-3">
                            <label>Access:</label>
                            <select
                                id="accessSelect"
                                name="access"
                                className={`form-control ${actionData?.errors?.access ? 'is-invalid' : ''}`}
                                value={selectedAccess}
                                onChange={(e) => setSelectedAccess(e.target.value)}
                            >
                                <option key="rw" value="rw">Read/Write</option>
                                <option key="ro" value="ro">Read Only</option>
                            </select>
                            {actionData?.errors?.access && (
                                <div className="invalid-feedback">{actionData.errors.access}</div>
                            )}
                        </div>
                        {!actionData?.success && (
                            <div className="text-end">
                                <button
                                    type="submit"
                                    className="btn btn-custom mb-3"
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? 'Uploading package...' : 'Create Package'}
                                </button>
                            </div>
                        )}
                    </Form>
                ) : (
                    <div> Action not supported</div>
                )}
            </div>
        </>
    );
}

export default PackageForm;


