import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useGlobalContext } from "../../GlobalContext";
import { api } from "../../api";
import { AxiosError } from "axios";
import { ROLE, useAuth } from "../../auth/AuthContext";
import {
  InstanceType
} from "../types";

interface InstanceRowProps {
  instance: InstanceType;
  setIsLoading: (isLoading: boolean) => void;
}

enum certificateStatusEnum {
  NA = "NO SSL",
  VALID = "VALID",
  EXPIRED = "EXPIRED",
  RENEW = "RENEW",
}

interface InstanceData {
  version?: string;
  isElasticIp: boolean;
  isOutOfSupport: boolean;
  sslDaysRemaining?: number;
  state?: string; // State of the SSL shared instance
}

let cache: any = {};
// TODO: Improve instance loading
/* let queue: { url: string; cb: Function }[] = [];
let inProgress = false;
const push = (url: string, cb: Function) => {
  queue.push({ url, cb: cb });
  if (!inProgress) {
    inProgress = true;
    triggerRequest(0);
  }
};
const triggerRequest = (i: number) => {
  if (queue.length > 0) {
    api
      .get(queue[i].url)
      .then(({ data }) => queue[i].cb(data))
      .catch(() => {})
      .finally(() => triggerRequest(i + 1));
  }
}; */

const getEffectiveIp = (instance: InstanceType) => {
  if (instance.hasLoadBalancer && instance.dns) {
    return instance.dns;
  }
  return instance.publicIpAddress;
};

const InstanceRow: FC<InstanceRowProps> = ({ instance, setIsLoading }) => {
  const { region } = useGlobalContext();
  const { canUser } = useAuth();
  const [sslDaysRemaining, setSslDaysRemaining] = useState<number | string>(0);
  const [sslStatus, setsslStatus] = useState(certificateStatusEnum.NA);
  const [isElasticIp, setIsElasticIp] = useState<boolean>(true);
  const [isOutOfSupport, setIsOutOfSupport] = useState<boolean>(false);
  const [instanceVersion, setInstanceVersion] = useState<string>("");
  // const [isInstanceReady, setIsInstanceReady] = useState<boolean>(false);
  const [instanceFamily, setInstanceFamily] = useState<string[]>([]);

  useEffect(() => {
    const effectiveIp = getEffectiveIp(instance);
    const endpoint = `/instances/${instance.instanceId}/data?region=${region}&ip=${effectiveIp}&lms=${instance.lms}&domain=${instance.domain}&deployment=${instance.deploymentType}`;
    const sharedEndpoint = `/instances/${instance.instanceId}/shared-server-data?region=${region}&ip=${effectiveIp}&lms=${instance.lms}&domain=${instance.domain}&deployment=${instance.deploymentType}`;
    const url = instance.deploymentType === "shared" ? sharedEndpoint : endpoint;

    const updateState = (data: InstanceData) => {
      //console.log(`${instance.name}: `, data);
      setIsElasticIp(data.isElasticIp);
      setIsOutOfSupport(data.isOutOfSupport);
      if (data.version){
        instance.version = data.version;
        setInstanceVersion(data.version);
      } 
      if (data.sslDaysRemaining){
        setSslDaysRemaining(data.sslDaysRemaining);
        setsslStatus(getCertificateStatus(data.sslDaysRemaining));
      }
      if(data.state){
        setsslStatus(data.state as certificateStatusEnum);
      }
    };

    if (cache[url]) return updateState(cache[url]);

    const controller = new AbortController();
    api
      .get<InstanceData>(url, { signal: controller.signal })
      .then(({ data }) => {
        cache[url] = data;
        updateState(data);
      })
      .catch(() => {});

    return () => {
      controller.abort();
    };
  }, [instance, region]);

  useEffect(() => {
    if (instance.instaceType) {
      const instanceFamily = getInstanceFamily(instance.instaceType);
      if (instanceFamily && instanceFamily.length > 0)
        setInstanceFamily(instanceFamily);
    }
    return () => {};
  }, [instance]);

  function renew(
    instanceId: string,
    instanceIp: string,
    sslStatus: certificateStatusEnum
  ) {
    console.log(sslStatus);
    const effectiveIp = getEffectiveIp(instance);
    if (
      sslStatus === certificateStatusEnum.RENEW ||
      sslStatus === certificateStatusEnum.EXPIRED
    ) {
      console.log("se puede renovar ip: ", instanceIp);
      api.post(`/instances/renew-ssl/${instanceId}`, {
        instanceIp: effectiveIp,//instanceIp,
        instanceName: instance.name,
        region: region,
      });
      /*   .then(() => {})
        .catch((err: any) => {
          if (err instanceof AxiosError) {
            if (err.response && err.response.data) {
              alert(
                `The certificate renewal process has not begun. ${err.response.data.message}`
              );
            }
          } else
            alert(
              `The certificate renewal process has not begun. ${err.message}`
            );
        }); */

      alert(`Certificate renewal process has started for ${instance.name}. You will receive an email upon completion of the process.`);
    } else alert("It is not necessary to renew the domain.");
  }

  const OnChangeInstanceType = async (newType: string) => {
    const selectedIndex = instanceFamily.indexOf(newType);
    const currentSelectedIndex = instanceFamily.indexOf(instance.instaceType);
    console.log("selectedIndex: ", selectedIndex);
    console.log("currentSelectedIndex: ", currentSelectedIndex);

    if (selectedIndex < currentSelectedIndex) {
      console.log("Seleccionó un tipo de instancia menor.");
      const confirmed = window.confirm(
        `You have selected a smaller instance type, you should consider its capabilities. Smaller instance types generally have less processing power, which can affect the performance and availability of your running applications or services. You should be aware of the performance implications before making any changes. 
Do you want to continue ?`
      );
      if (!confirmed) {
        console.log("Se ha cancelado la operacion.");
        return; // No se ejecuta modifyInstanceType si el usuario cancela la confirmación
      }
    }

    console.log("Se ejecutará la operacion.");
    // Realizar el cambio de tipo de instancia
    modifyInstanceType(newType);
  };
  const modifyInstanceType = async (newType: string) => {
    if (newType) {
      const region =
        window.localStorage.getItem("moodle-console-region") ?? "us-west-2";

      try {
        setIsLoading(true);
        // Call endpoint
        const { data } = await api.put(
          `/instances/${instance.instanceId}?region=${region}&instanceType=${newType}`
        );
        console.log("response modifyInstanceType: ", data);

        setIsLoading(false);
        if (data.success) {
          alert(
            `The operation was executed successfully. 
Please note that it may take a few minutes for the instance change to be reflected.`
          );
        }
      } catch (error: any) {
        setIsLoading(false);

        if (error instanceof AxiosError && error.response) {
          const response = error.response;
          const data = response.data;
          alert(data.message);
        } else alert(error.message);
      }
    }
  };

  const canUpgrade = instance.lms === 'Moodle' && instanceVersion && isVersionGreaterOrEqual(instanceVersion, '4.3');

  const handleUpgradeClick = () => {
    if (instance.deploymentType === 'shared') {
      alert('This LMS cannot be upgraded because it is a shared instance.');
      return;
    }
    if (instance.lms !== 'Moodle') {
      alert('This LMS cannot be upgraded because it is not Moodle.');
      return;
    }
    if (!instanceVersion) {
      alert('This LMS cannot be upgraded because it does not have version data.');
    } else if (!isVersionGreaterOrEqual(instanceVersion, '4.3.3')) {
      alert('This LMS cannot be upgraded because its version is lower than 4.3');
    }
  };

  function isVersionGreaterOrEqual(version1: string, version2: string): boolean {
    // Remueve el '+' de las versiones para poder compararlas numéricamente
    const hasPlus1 = version1.endsWith('+');
    const hasPlus2 = version2.endsWith('+');
  
    const v1 = version1.replace('+', '').split('.').map(Number);
    const v2 = version2.replace('+', '').split('.').map(Number);
  
    // Itera a través de las partes de ambas versiones y compara.
    for (let i = 0; i < Math.max(v1.length, v2.length); i++) {
      const num1 = v1[i] || 0; // Si no existe el índice i en v1, usa 0
      const num2 = v2[i] || 0; // Si no existe el índice i en v2, usa 0
  
      if (num1 > num2) return true;  // Si version1 es mayor en la parte actual
      if (num1 < num2) return false; // Si version2 es mayor en la parte actual
      // Si son iguales, se continúa con la siguiente parte
    }
  
    // Si las versiones numéricas son iguales, pero una tiene '+', se determina cuál es mayor
    if (hasPlus1 && !hasPlus2) return true;   // "4.3.3+" es mayor que "4.3.3"
    if (!hasPlus1 && hasPlus2) return false;  // "4.3.3" es menor que "4.3.3+"
  
    // Si ambas tienen el '+', o ninguna lo tiene, entonces son equivalentes o version1 es mayor o igual
    return true;
  } 

  return (
    <>
      <tr>
        <th scope="row" className={instance.state}>
          {instance.state}
        </th>
        <td 
          className={isOutOfSupport ? "out-of-support" : ""} 
          title={isOutOfSupport ? "Out of Support System Operating" : ""}
        >
          {instance.name}
        </td>
        {
          <td className="center-td">
            {canUser([ROLE.ADMIN, ROLE.SUPERADMIN]) ? (
              <select
                className="form-select"
                value={instance.instaceType}
                onChange={async (e) => {
                  if (
                    window.confirm(
                      `Are you sure you want to perform the operation Modify Instance Type on ${instance.name} instance?`
                    )
                  )
                    OnChangeInstanceType(e.target.value);
                }}
              >
                {instanceFamily &&
                  instanceFamily.length > 0 &&
                  instanceFamily.map((type: string) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
              </select>
            ) : (
              instance.instaceType
            )}
          </td>
        }
        <td>{instanceVersion}</td>
        <td>{instance.instanceId}</td>
        <td className={!isElasticIp ? "not-elastic-ip" : ""}>
          {instance.publicIpAddress}
        </td>
        <td>
          <button
            type="button"
            title={ 
              (instance.deploymentType !== "shared" && sslStatus !== "NO SSL" && sslStatus !== "EXPIRED")
              ? `${sslDaysRemaining} days remaining for the SSL certificate to expire`
              : ''
            }
            className={
              sslStatus === "NO SSL"
                ? `btn ssl-${sslStatus} no-ssl`
                : `btn ssl-${sslStatus}`
            }
            onClick={(e) => {
              if (
                window.confirm(
                  `Are you sure you want to renew the domain of the instance ${instance.name}?`
                )
              )
                renew(instance.instanceId, instance.publicIpAddress, sslStatus);
            }}
          >
            {sslStatus}
          </button>
        </td>
        <td>
          <div className="mt-2">
            <Link
              title="Actions"
              className="options-table-instances actions-icon"
              to={`actions/${instance.instanceId}/${instance.name}/${instance.deploymentType}`}
            >
            </Link>

            <Link
              title="Commands"
              className="options-table-instances commands-icon"
              to={`commands/${instance.instanceId}/${getEffectiveIp(instance)}/${instance.name}`}
            >
            </Link>

            <Link
              title="Uploads"
              className="options-table-instances uploads-icon"
              to={`updloads/${instance.instanceId}/${getEffectiveIp(instance)}/${instance.lms}`}
            ></Link>

            <Link
              title="Information"
              className="options-table-instances information-icon"
              to={`information/${instance.instanceId}/${getEffectiveIp(instance)}/${instance.name}/${instance.deploymentType}/${instanceVersion}`}
            ></Link>

            {instance.lms === 'Moodle' && canUpgrade ? (
                <Link
                  title="Updates"
                  className="options-table-instances updates-icon"
                  to={`${instance.instanceId}/upgrade/${instance.name}/${instance.deploymentType}`}
                > </Link>
              ) : (
                <a
                  href="/#"
                  title="Updates"
                  className="options-table-instances updates-icon"
                  onClick={(e) => {
                    e.preventDefault();
                    handleUpgradeClick();
                  }}
                > </a>
              )}
          </div>
        </td>
      </tr>
    </>
  );
};

function getCertificateStatus(sslDays: string | number): certificateStatusEnum {
  if (typeof sslDays === "string" && sslDays === "NO SSL") {
    return certificateStatusEnum.NA;
  }

  if (typeof sslDays === "number")
    switch (true) {
      case sslDays <= 0:
        return certificateStatusEnum.EXPIRED;
      case sslDays < 30:
        return certificateStatusEnum.RENEW;
      case sslDays >= 30:
        return certificateStatusEnum.VALID;
      default:
        return certificateStatusEnum.NA;
    }

  return certificateStatusEnum.NA;
}

function getInstanceFamily(instanceType: string) {
  const instanceTypeMapping = {
    t2: "t2",
    t3: "t3",
    t3a: "t3a",
    t4g: "t4g",
    m5: "m5",
  };

  const sizes = ["nano", "micro", "small", "medium", "large", "xlarge", "2xlarge"];
  const large_sizes = ["large", "xlarge", "2xlarge"];

  const type = instanceType.split(".")[0]; //Extract the type from the instance type. Ex: t3 from t3.small

  if (type in instanceTypeMapping) {
    const instanceFamily = type === "m5" ? large_sizes : sizes;
    return instanceFamily.map((t) => `${type}.${t}`);
  } else {
    return [];
  }
}

export default InstanceRow;
