export enum ProfileEnum {
    MANAGER = "manager",
    ADMIN = "admin",
    SUPERADMIN = "superadmin"
}

export type UserType = {
    id: string;
    firstName: string;
    lastName: string;
    type: ProfileEnum;
    password?: string;
}

export interface ValidationErrors {
    id?: string;
    firstName?: string;
    password?: string;
    type?: string;
    confirmPassword?: string;
    newPassword?: string;
  }

export interface IActionUserForm {
    success?: boolean;
    errors?: ValidationErrors;
    message?: string;
}

export interface PackageType {
    key: string;
    size: string;
    lastModified: string;
    access: PackageAccess;
}

export interface IActionPackageForm {
    success?: boolean;
    errors?: PackageValidationErrors;
    message?: string;
}

export interface PackageValidationErrors {
    file?: string;
    access?: string;
    category?: string;
    pluginPath?: string;
}

export enum PackageAccess {
    RW = 'rw',
    RO = 'ro'
}

export const ACCEPTED_PACKAGE_TYPES = [
    'application/zip',
    'application/x-gzip',
];

export const DISPLAY_PACKAGE_TYPES = [
    '.zip', 
    '.tgz',
    '.gz',
    '.tar.gz' 
];

export const MOODLE_PLUGIN_PATHS = [
    'admin/tool',
    'admin/tool/log/store',
    'auth',
    'availability/condition',
    'blocks',
    'calendar/type',
    'contentbank/contenttype',
    'course/format',
    'course/report',
    'customfield/field',
    'dataformat',
    'enrol',
    'files/converter',
    'filter',
    'grade/export',
    'grade/grading/form',
    'grade/import',
    'grade/report',
    'h5p/h5plib',
    'lib/editor',
    'lib/editor/atto/plugins',
    'local',
    'media/player',
    'mod',
    'plagiarism',
    'portfolio',
    'report',
    'search/engine',
    'theme',
    'user/profile/field'
] as const;
