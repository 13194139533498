import { UserType, ProfileEnum } from "./types";
import { Link } from "react-router-dom";

interface UserTableProps {
    users: UserType[];
    onDeleteUser: (userId: string) => Promise<void>;
}

const UserTable: React.FC<UserTableProps> = ({ users, onDeleteUser }) => {

    return (
        users && users.length > 0 ? (
            <div className="table-responsive">
                <table id="admin-table" className="table table-bordered table-hover table-sm align-middle w-100 text-wrap">
                    <thead>
                        <tr>
                            <th scope="col">EMAIL</th>
                            <th scope="col">NAME</th>
                            <th scope="col">PROFILE</th>
                            <th scope="col">ACTIONS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user: UserType) => (
                            <tr key={user.id}>
                                <td>{user.id}</td>
                                <td>{user.firstName} {user.lastName}</td>
                                <td>{user.type as ProfileEnum}</td>
                                <td>
                                    <div className="d-flex gap-2 justify-content-center">
                                        <Link
                                            title="Edit User"
                                            className="options-table-admin edit-icon"
                                            to={`edit/${user.id}`}
                                        >
                                            <span className="d-inline-block user-icon-dimensions"></span>
                                        </Link>
                                        <button
                                            title="Delete User"
                                            className="options-table-admin delete-icon"
                                            onClick={() => onDeleteUser(user.id)}
                                        >
                                            <span className="d-inline-block user-icon-dimensions"></span>
                                        </button>
                                        <Link
                                            title="Reset Password"
                                            className="options-table-admin reset-password-icon"
                                            to={`reset-pass/${user.id}`}
                                        >
                                            <span className="d-inline-block user-icon-dimensions"></span>
                                        </Link>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        ) : (
            <div>No users found</div>
        )
    );
};

export default UserTable;
