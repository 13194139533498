import { PackageAccess, PackageType } from "./types";

interface PackageTableProps {
    packages: PackageType[];
    onUpdateAccess: (key: string, access: string) => void;
    onDeletePackage: (key: string, access: string) => void;
}

const PackageTable: React.FC<PackageTableProps> = ({packages, onDeletePackage, onUpdateAccess }) => {
    return (
        packages && packages.length > 0 ? (
            <div className="table-responsive table-container">
                <table id="admin-table" className="table table-bordered table-hover table-sm align-middle w-100 text-wrap">
                    <thead>
                        <tr>
                            <th scope="col">NAME</th>
                            <th scope="col">SIZE</th>
                            <th scope="col">LAST MODIFIED</th>
                            <th scope="col">ACCESS</th>
                            <th scope="col">ACTIONS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {packages.map((object: PackageType) => (
                            <tr key={object.key}>
                                <td>{object.key}</td>
                                <td>{object.size}</td>
                                <td>{object.lastModified}</td>
                                <td>{(object.access as PackageAccess) === PackageAccess.RW ? 'READ/WRITE' : 'READ ONLY'}</td>
                                <td>
                                    <div className="d-flex gap-2 justify-content-center">
                                        <div className="action-item">
                                            <button
                                                title="Update Access"
                                                className="options-table-admin edit-icon"
                                                onClick={() => onUpdateAccess(
                                                    object.key, 
                                                    (object.access as PackageAccess) === PackageAccess.RW 
                                                        ? PackageAccess.RO 
                                                        : PackageAccess.RW
                                                )}
                                            >
                                                <span className="d-inline-block user-icon-dimensions"></span>
                                            </button>
                                        </div>
                                        <div className="action-item">
                                            {(object.access as PackageAccess) === PackageAccess.RW ? (
                                                <button
                                                    title="Delete Package"
                                                    className="options-table-admin delete-icon"
                                                    onClick={() => onDeletePackage(object.key, object.access)}
                                                >
                                                    <span className="d-inline-block user-icon-dimensions"></span>
                                                </button>
                                            ) : null}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        ) : (
            <div>No packages found</div>
        )
    );
};

export default PackageTable;
