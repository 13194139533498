import { useEffect, useState } from "react";
import {
  Form,
  redirect,
  useLoaderData,
  useNavigate,
  useNavigation
} from "react-router-dom";
import { api } from "../../api";
import { AxiosError } from "axios";
import type {
  ILoaderAddStandaloneInstance,
  InstanceRequest
} from "./types";

const AddStandaloneInstance = () => {
  const data = useLoaderData() as ILoaderAddStandaloneInstance;
  console.log("Loader: ", data);
  // Clean token from localStorage
  window.localStorage.setItem("moodle-console-instance-request", '');

  const navigation = useNavigation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [requestData, setRequestData] = useState<InstanceRequest | null>(null);

  const [contentOuput, setContentOuput] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState("");

  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [showOutput, setShowOutput] = useState<boolean>(false);
  const [outputFileId, setOutputFileId] = useState<string>("");

  const capitalizeFirstLetter = (string: string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const createInstance = async (request: InstanceRequest, token: string) => {
    try {
      setIsLoading(true);

      // Construir el objeto para la solicitud POST a la API
      const requestBody = {
        installationType: 'standalone',
        installationPackage: request.installationPackage,
        installThemeEdumy: request.installThemeEdumy?.toString() || 'false',
        totaraType: request.totaraType,
        client: request.client,
        contractedUsers: request.contractedUsers,
        branch: request.branch,
        domain: request.domain,
        instanceName: request.instanceName,
        instanceType: request.instanceType,
        volumeSize: request.volumeSize,
        moodleInstallationType: request.moodleInstallationType,
        dbOnSharedRDS: request.dbOnSharedRDS?.toString() || 'false',
        requestedBy: request.requestedBy,
        requestToken: token,
        //Parametros de configuracion del LMS
        logoDefault: request.logoDefault,
        logoDark: request.logoDark,
        loginBGImage: request.loginBGImage,
        primaryColor: request.primaryColor,
        accentColor: request.accentColor,
        secondaryColor: request.secondaryColor,
        footerColor: request.footerColor,
        clientEmail: request.clientEmail,
        sendEmailToClient: request.sendEmailToClient?.toString() || 'false',
        ...(request.dbInstance && request.dbInstance.trim() !== '' && { dbInstance: request.dbInstance })
      };
      //console.log("requestBody: ", requestBody);

      // Realizar la solicitud POST a la API
      const response = await api.post(`/instance`, requestBody);
  
      const data = response.data;
      //setIsLoading(false);
      if (data.success) {
        console.log("response ok: ", data);
        if (data.startCreationProcess && data.deployId && data.deployId !== ''){
          //setIsLoading(true);
          setIsCreating(data.startCreationProcess);
          setOutputFileId(data.deployId);
          setShowOutput(true);
        }
        // else {
        //   alert("No information has been received about the process. Please contact system support.");
        // }
      }
    } catch (error: any) {
      setIsCreating(false);
      setOutputFileId("");
      setShowOutput(false);
      setIsLoading(false);
      if (error instanceof AxiosError && error.response) {
        const response = error.response;
        const { data } = response;
  
        //not authorized
        if (response.status === 401) 
          return redirect("/");
        else if (response.status === 400 && data.fieldsError) //incorrect parameters
          alert("Incorrect parameters. Please check the data entered.")
        else
          alert(data.message); //another error
      }
    }
  }
  
  useEffect(() => {
      console.log('Ejecutando el useEffect de cargar datos del componente..................');
      let isMounted = true; // Flag to indicate when the component is mounted
      setIsLoading(true); //show loading element

      // Getting standlone instance data
      const token = data.token;
      api
        .get(`/instance/confirm-instance/${token}`)
        .then(({ data }) => {
          if (isMounted) {  // If the component is mounted update the state with the data
            console.log("Data del Request: ", data);
            if (data.success && data.data) {
              const request: InstanceRequest = data.data;
              setRequestData(request);
              createInstance(request, token);
            }
          }
        })
        .catch((err) => {
          if (isMounted) { // If the component is mounted update the state with the data
            if (err instanceof AxiosError && err.response) {
              setErrorMessage(err.response.data.message);
            } else if (err.error && err.message) {
              setErrorMessage(err.message);
            } else {
              setErrorMessage("An error occurred while loading the data");
            }
            setIsLoading(false); //hide loading element
          }
        });

      return () => {
        isMounted = false; // clean up function to indicate the component is not mounted
      };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
 
  useEffect(() => {
    if (isCreating) {
      console.log("Obteniendo datos de la creacion de instancia...")
      let timeout: number;

      const loopRequest = () => {
        timeout = window.setTimeout(() => {
          const url = `/instance/creation-process-output?outputFileId=${outputFileId}`;
          api
            .get(url)
            .then(async (response) => {
              const output = response.data;
              if (response.status === 200) {
                //finished successfully
                setIsCreating(false);
                setOutputFileId("");
                setContentOuput(output);
                setIsLoading(false);
              } else if (response.status === 206) {
                //continue the process
                setContentOuput(output);
                loopRequest();
              } /* else if (response.status === 500) {
                //finished  with errors
                setIsCreating(false);
                setContentOuput(output);
              } */
            })
            .catch((error) => {
              // do something with the error
              console.log(error.message);
              setIsCreating(false);
              setIsLoading(false);
              setErrorMessage(error.message);
            });
        }, 3000);
      };

      loopRequest();

      return () => {
        if (typeof timeout === "number") clearTimeout(timeout);
      };
    }
  }, [isCreating, outputFileId]);

  return (
    <div className="container ">
      <div
        className={
          isLoading || navigation.state === "loading" || navigation.state === "submitting"
            ? "loading"
            : ""
        }
      ></div>
      <div className="row">
        <div className="col-12 pt-3">
          <h2 id="add-instance-title">Add Standalone Instance</h2>
          <div className="row mt-4 add-instance-container h-80">
            <div className="col-6 col-md-6 col-lg-5">
              <h4>LMS Information</h4>
              <Form className="h-100">
                <div id="add-instance-form" className="mt-3">
                  {errorMessage && (
                    <div className="mt-3 field-error">
                      <p>{errorMessage}</p>
                    </div>
                  )}
                  <div className="mb-1">
                    <label
                      className="form-label"
                      htmlFor="installationPackageInput"
                    >
                      Installation Package
                    </label>
                    <input
                      className="form-control"
                      name="installationPackage"
                      aria-label="Default select example"
                      id="installationPackageInput"
                      defaultValue={requestData?.installationPackage}
                      readOnly={true} 
                    />
                  </div>
                  {requestData?.lms === 'Moodle' && (
                    <>
                      <div className="mb-3">
                        <div className="form-check m-edumy">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value="edumyTheme"
                            name="edumyThemeCheck"
                            id="flexCheckDefault"
                            defaultChecked={requestData?.installThemeEdumy || false}
                            readOnly={true} 
                            disabled={true}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                          >
                            Edumy Theme
                          </label>
                        </div>
                      </div>
                      { requestData?.moodleInstallationType && (
                        <div className="mb-3">
                          <label className="form-label" htmlFor="moodleInstallationTypeInput">
                            Moodle Installation Type
                          </label>
                          <input
                            className="form-control"
                            name="moodleInstallationType"
                            id="moodleInstallationTypeInput"
                            aria-label="Default select example"
                            defaultValue={capitalizeFirstLetter(requestData?.moodleInstallationType)}
                            readOnly={true} 
                          />
                        </div>
                      )}
                    </>
                  )}
                  {requestData?.lms === 'Totara' && (
                    <div className="mb-3">
                      <label className="form-label" htmlFor="totaraTypeInput">
                        Totara Type
                      </label>
                      <input
                        className="form-control"
                        name="totaraType"
                        id="totaraTypeInput"
                        aria-label="Default select example"
                        defaultValue={capitalizeFirstLetter(requestData?.totaraType)}
                        readOnly={true} 
                      />
                    </div>
                  )}
                  <div className="mt-3 mb-3">
                    <label className="form-label" htmlFor="clientInput">
                      Client
                    </label>
                    <input
                      className="form-control"
                      id="clientInput"
                      name="client"
                      type="text"
                      defaultValue={requestData?.client || ""}
                      readOnly={true}
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      className="form-label"
                      htmlFor="contractedUsersInput"
                    >
                      Contracted Users
                    </label>
                    <input
                      className="form-control"
                      name="contractedUsers"
                      id="contractedUsersInput"
                      type="number"
                      defaultValue={requestData?.contractedUsers || ""}
                      readOnly={true}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="branchInput">
                      Branch
                    </label>
                    <input
                      className="form-control"
                      name="branch"
                      id="branchInput"
                      aria-label="Default select example"
                      defaultValue={requestData?.branch || ""}
                      readOnly={true}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="instanceDomainInput">
                      Domain
                    </label>
                    <input
                      className="form-control"
                      id="instanceDomainInput"
                      name="instanceDomain"
                      type="text"
                      defaultValue={requestData?.domain || ""}
                      readOnly={true}
                    />
                  </div>
                  <label className="form-label" htmlFor="instanceNameInput">
                    Instance Name
                  </label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="instanceName"
                      id="instanceNameInput"
                      defaultValue={requestData?.instanceName || ""}
                      readOnly={true}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="volumeSizeInput">
                      Volume Size
                    </label>
                    <input
                      className="form-control"
                      name="volumeSize"
                      id="volumeSizeInput"
                      type="number"
                      defaultValue={requestData?.volumeSize || ""}
                      readOnly={true}
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      className="form-label"
                      htmlFor="instanceTypeInput"
                    >
                      Instance Type
                    </label>
                    <input
                      className="form-control"
                      name="instanceType"
                      id="instanceTypeInput"
                      aria-label="Default select example"
                      defaultValue={requestData?.instanceType || ""}
                      readOnly={true}
                    />
                  </div>
                  <div className="mb-3">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="standalone"
                        name="rdsCheck"
                        id="standaloneRadio"
                        defaultChecked={!requestData?.dbOnSharedRDS}
                        readOnly={true}
                        disabled={true}
                      />
                      <label className="form-check-label" htmlFor="standaloneRadio">
                        Standalone RDS
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="shared"
                        name="rdsCheck"
                        id="siloRadio"
                        defaultChecked={requestData?.dbOnSharedRDS}
                        readOnly={true}
                        disabled={true}
                      />
                      <label className="form-check-label" htmlFor="siloRadio">
                        Shared RDS
                      </label>
                    </div>
                  </div>
                  {!requestData?.dbOnSharedRDS && (
                    <div className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="dbinstanceNameInput"
                      >
                        RDS Name
                      </label>
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          name="dbinstanceName"
                          id="dbinstanceNameInput"
                          placeholder="Name"
                          aria-label="Name"
                          defaultValue={requestData?.dbInstance || ""}
                          readOnly={true}
                        />
                      </div>
                    </div>
                  )}
                  {/* Parametros de configuracion del LMS */}
                  {requestData?.installThemeEdumy && (
                    <>
                      <div className="mb-3">
                        <label className="form-label" htmlFor="clientEmailInput">
                          Client Email
                        </label>
                        <input
                          className="form-control"
                          id="clientEmailInput"
                          name="clientEmail"
                          type="text"
                          defaultValue={requestData?.clientEmail || ""}
                          readOnly={true}
                        />
                      </div>

                      <div className="mb-3">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="sendEmailCheck"
                            checked={requestData?.sendEmailToClient || false}
                            readOnly={true}
                            disabled={true}
                          />
                          <label className="form-check-label" htmlFor="sendEmailCheck">
                            Send Email to Client
                          </label>
                        </div>
                      </div>

                      <div className="mb-3">
                        <label className="form-label">Theme Customization</label>
                        <div className="row g-3">
                          <div className="col-md-6">
                            <label className="form-label">Primary Color</label>
                            <div className="input-group">
                              <div 
                                style={{
                                  width: '38px',
                                  backgroundColor: requestData?.primaryColor || '#FFFFFF',
                                  border: '1px solid #ccc',
                                  borderRadius: '4px 0px 0px 4px'
                                }}
                              />
                              <input
                                className="form-control"
                                type="text"
                                defaultValue={requestData?.primaryColor || ""}
                                readOnly={true}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <label className="form-label">Secondary Color</label>
                            <div className="input-group">
                            <div 
                                style={{
                                  width: '38px',
                                  backgroundColor: requestData?.secondaryColor || '#FFFFFF',
                                  border: '1px solid #ccc',
                                  borderRadius: '4px 0px 0px 4px'
                                }}
                              />
                            <input
                              className="form-control"
                              type="text"
                              defaultValue={requestData?.secondaryColor || ""}
                              readOnly={true}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <label className="form-label">Accent Color</label>
                            <div className="input-group">
                            <div 
                                style={{
                                  width: '38px',
                                  backgroundColor: requestData?.accentColor || '#FFFFFF',
                                  border: '1px solid #ccc',
                                  borderRadius: '4px 0px 0px 4px'
                                }}
                              />
                            <input
                              className="form-control"
                              type="text"
                              defaultValue={requestData?.accentColor || ""}
                              readOnly={true}
                            />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <label className="form-label">Footer Color</label>
                            <div className="input-group">
                              <div 
                                style={{
                                  width: '38px',
                                  backgroundColor: requestData?.footerColor || '#FFFFFF',
                                  border: '1px solid #ccc',
                                  borderRadius: '4px 0px 0px 4px'
                                }}
                              />
                              <input
                              className="form-control"
                              type="text"
                              defaultValue={requestData?.footerColor || ""}
                              readOnly={true}
                            />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mb-3">
                        <label className="form-label">Theme Images</label>
                        <div className="row g-3">
                          <div className="col-md-4">
                            <label className="form-label">Logo</label>
                            <input
                              className="form-control"
                              style={{ backgroundColor: "rgba(67, 170, 69, 0.200)" }}
                              type="text"
                              defaultValue={requestData?.logoDefault ? requestData?.logoDefault  : ""}
                              readOnly={true}
                            />
                          </div>
                          <div className="col-md-4">
                            <label className="form-label">Dark Logo</label>
                            <input
                              className="form-control"
                              style={{ backgroundColor: "rgba(67, 170, 69, 0.200)" }}
                              type="text"
                              defaultValue={requestData?.logoDark ? requestData?.logoDark : ""}
                              readOnly={true}
                            />
                          </div>
                          <div className="col-md-4">
                            <label className="form-label">Login Background</label>
                            <input
                              className="form-control"
                              style={{ backgroundColor: "rgba(67, 170, 69, 0.200)" }}
                              type="text"
                              defaultValue={requestData?.loginBGImage ? requestData?.loginBGImage : ""}
                              readOnly={true}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="text-end">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => navigate('/home/instances')}
                    >
                      Instance List
                    </button>
                  </div>                  
                </div>
              </Form>
            </div>
            {showOutput && (
              <div className="col-6 col-md-6 col-lg-5 d-flex flex-column">
                <h4>Instance Creation Process</h4>
                <div
                  id="addInstace-output-board"
                  className="form-control mt-3 flex-grow-1"
                >
                  {contentOuput}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddStandaloneInstance;