import { useEffect } from "react";
import { Form, useLoaderData, useActionData } from "react-router-dom";

interface IActionQrScan {
  twoFactor?: string;
  fieldError?: boolean;
  error: boolean;
  messagge?: string;
}

function QrScan() {
  let data: any = useLoaderData();
  console.log("data del loader: ", data);

  const dataAction = useActionData() as IActionQrScan | undefined;
  console.log("response del actionData: ", dataAction);

  useEffect(() => {
    console.log("ejecutando effect");
    document.body.classList.add("login");

    return () => {
      document.body.classList.remove("login");
    };
  }, []);

  return (
    <main className="form-qrcode w-100 m-auto">
      <Form method="post">
        <img className="mb-1 w-100 px-3" src="./images/logo-white.png" alt="" />

        <div className="row">
          <div className="col-sm-12">
            <h4> Scan your code</h4>
          </div>
          <div className="col-sm-12 mt-3">
            <img src={data.qr} alt="" />
          </div>
        </div>

        <div className="row justify-content-center mt-2">
          <label className="col-sm-12 col-form-label" htmlFor="twoFactor">
            Enter token to enable two factor authentication
          </label>
          <div className="col-sm-6">
            <input
              type="text"
              className={`${
                dataAction?.fieldError ? "form-control wrong" : "form-control"
              }`}
              name="twoFactor"
            />
          </div>
        </div>

        {dataAction?.messagge && (
          <div className="col-sm-12 mt-1 text-end">
            <p>{dataAction?.messagge}</p>
          </div>
        )}

        <div className="row mt-3">
          <div className="col-sm-12">
            <button className="btn btn-md btn-primary px-4" type="submit">
              Verify
            </button>
          </div>
        </div>
      </Form>
    </main>
  );
}

export default QrScan;
